import { useState } from "react";
import apiClient from "../api/axios";
import { placeholderUser, useUser } from "../context/userContext";

const useLogout = () => {
  const { setUser } = useUser();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await apiClient.post("/auth/logout");
      localStorage.removeItem("token");
      setUser(placeholderUser);
    } catch (error) {
      console.error("Logout error", error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return { handleLogout, isLoggingOut };
};

export default useLogout;
