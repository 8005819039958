import { Outlet, useNavigate } from "react-router-dom";
import { View } from "@aws-amplify/ui-react";

import { Header } from "./components/Header";
import "./app.scss";
import { useEffect } from "react";
import { useUser } from "./context/userContext";

function Front() {
  return (
    <View className="container container-front">
      <View className={"section-container"}>
        <Header />
        <View className="main-container">
          <Outlet />
        </View>
      </View>
    </View>
  );
}

export default Front;
