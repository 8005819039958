import { useState, useEffect } from "react";
import { DateTime } from "luxon";

import { timeZone, getMonthsAndYears, getWeeksInMonth } from "../../utils";

const findCurrentWeek = (weeks) => {
  const now = DateTime.now().setZone(timeZone);
  return weeks.find(
    (week) =>
      DateTime.fromISO(week.start).setZone(timeZone) <= now &&
      now <= DateTime.fromISO(week.end).setZone(timeZone)
  );
};

export const WeekSelector = ({
  isDisabled = false,
  selectedMonthYear,
  setSelectedMonthYear,
  selectedWeek,
  setSelectedWeek
}) => {
  const [monthsAndYears, setMonthsAndYears] = useState([]);
  const [weeks, setWeeks] = useState([]);

  useEffect(() => {
    const monthsYears = getMonthsAndYears();
    setMonthsAndYears(monthsYears);

    const now = DateTime.now().setZone(timeZone);
    const initialMonthYear =
      now.year > 2024 || (now.year === 2024 && now.month >= 6)
        ? { month: now.month, year: now.year }
        : { month: 6, year: 2024 };

    setSelectedMonthYear(initialMonthYear);
    updateWeeks(initialMonthYear.month, initialMonthYear.year);
  }, []);

  const updateWeeks = (month, year) => {
    const weeks = getWeeksInMonth(month, year);
    setWeeks(weeks);
    const currentWeek = findCurrentWeek(weeks);
    setSelectedWeek(currentWeek || {});
  };

  const handleMonthYearChange = (e) => {
    const [month, year] = e.target.value.split("-").map(Number);
    if (!month || !year) {
      setSelectedMonthYear({});
      setWeeks([]);
      setSelectedWeek({});
    } else {
      setSelectedMonthYear({ month, year });
      updateWeeks(month, year);
    }
  };

  const handleWeekChange = (e) => {
    const [start, end] = e.target.value.split("|");
    setSelectedWeek({ start, end });
  };

  const renderWeekOption = (week, index) => (
    <option key={index} value={`${week.start}|${week.end}`}>
      {`Week of ${DateTime.fromISO(week.start)
        .setZone(timeZone)
        .toFormat("LLL dd")} - ${DateTime.fromISO(week.end)
        .setZone(timeZone)
        .toFormat("LLL dd, yyyy")}`}
    </option>
  );

  return (
    <div>
      <div className="select-container">
        <select
          disabled={isDisabled}
          value={
            selectedMonthYear.month
              ? `${selectedMonthYear.month}-${selectedMonthYear.year}`
              : ""
          }
          onChange={handleMonthYearChange}
        >
          <option value="">Select Month and Year</option>
          {monthsAndYears.map((my, index) => (
            <option key={index} value={`${my.month}-${my.year}`}>
              {DateTime.fromObject(
                { month: my.month, year: my.year, day: 1 },
                { zone: timeZone }
              ).toFormat("MMMM yyyy")}
            </option>
          ))}
        </select>
      </div>
      <div className="select-container">
        <select
          value={
            selectedWeek.start
              ? `${selectedWeek.start}|${selectedWeek.end}`
              : ""
          }
          onChange={handleWeekChange}
          disabled={
            isDisabled || !selectedMonthYear.month || !selectedMonthYear.year
          }
        >
          <option value="">Select Week</option>
          {weeks.map(renderWeekOption)}
        </select>
      </div>
      {selectedWeek.start && selectedWeek.end && (
        <div style={{ display: "none" }}>
          <p>
            Start of Week (ISO):{" "}
            {DateTime.fromISO(selectedWeek.start).toUTC().toISO()}
          </p>
          <p>
            End of Week (ISO):{" "}
            {DateTime.fromISO(selectedWeek.end).toUTC().toISO()}
          </p>
        </div>
      )}
    </div>
  );
};
