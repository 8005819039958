import { Autocomplete, Flex, Heading, View, Text } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";

import {
  prepareAccountantLoadsTableData,
  prepareAccountantPayoutTableData,
  prepareAccountantSummaryTableData,
  filterLoads,
  formatCycleRange
} from "../../utils";
import { AccountantLoads } from "../../components/Accountant/Loads";
import { SubButton } from "../../components/SubButton";
import { AccountantSummary } from "../../components/Accountant/Summary";
import { useLoads } from "../../services/loadService";
import { AccountantPayout } from "../../components/Accountant/Payout";
import { useCycles } from "../../services/cycleService";
import { useSalaries } from "../../services/salaryService";
import { useSettings } from "../../services/settingsService";
import { WeekSelector } from "../../components/WeekSelector";
import { set } from "date-fns";

export const AccountantScreen = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { data: loadsData, isLoading: isLoadingLoads } = useLoads(
    startDate,
    endDate
  );
  const { data: cyclesData, isLoading: isLoadingCycles } = useCycles(
    startDate,
    endDate
  );
  const { data: salariesData, isLoading: isLoadingSalaries } = useSalaries(
    startDate,
    endDate
  );
  const { data: settingsData, isLoading: isLoadingSettings } = useSettings(
    startDate,
    endDate
  );

  const [data, setData] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState({});
  const [selectedWeek, setSelectedWeek] = useState({});
  const [activeScreen, setActiveScreen] = useState("summary");

  const [unpaidFilter, setUnpaidFilter] = useState(false);
  const [missingInvoiceFilter, setMissingInvoiceFilter] = useState(false);
  const [driverFilter, setDriverFilter] = useState("");
  const [driverFilterOptions, setDriverFilterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState<
    "owners" | "companyDrivers" | null
  >(null);
  const [sub, setSub] = useState(false);
  const [selectedCycle, setSelectedCycle] = useState(null);

  // Loading states for each screen
  const [summaryDataLoaded, setSummaryDataLoaded] = useState(false);
  const [loadsDataLoaded, setLoadsDataLoaded] = useState(false);
  const [payoutDataLoaded, setPayoutDataLoaded] = useState(false);

  // Handle data fetching and table updates
  const fetchData = useCallback(() => {
    if (
      isLoadingLoads ||
      isLoadingCycles ||
      isLoadingSalaries ||
      isLoadingSettings ||
      !loadsData
    ) {
      return [];
    }

    if (activeScreen === "loads") {
      const prepared = prepareAccountantLoadsTableData(loadsData);
      setData(prepared);
      setDriverFilterOptions(
        Array.from(new Set(prepared.map((load) => load.driverName))).map(
          (driverName) => ({
            label: driverName,
            id: driverName
          })
        ) ?? []
      );
      setLoadsDataLoaded(true); // Set loads data as loaded
    } else if (activeScreen === "summary") {
      setData(prepareAccountantSummaryTableData(loadsData));
      setSummaryDataLoaded(true); // Set summary data as loaded
    } else if (activeScreen === "payout") {
      if (
        //        cyclesData?.length > 0 &&
        salariesData?.length > 0 &&
        settingsData?.length > 0
      ) {
        setData(
          prepareAccountantPayoutTableData(
            loadsData || [],
            cyclesData || [],
            salariesData,
            [settingsData?.[0]?.value || 0, 10]
          )
        );
        setPayoutDataLoaded(true); // Set payout data as loaded
      }
    }
  }, [
    activeScreen,
    loadsData,
    cyclesData,
    salariesData,
    settingsData,
    isLoadingLoads,
    isLoadingCycles,
    isLoadingSalaries,
    isLoadingSettings
  ]);

  // Set appropriate date range when the screen changes
  useEffect(() => {
    // Clear data before loading new data
    setData([]);

    // Reset the data loading states for all screens
    setSummaryDataLoaded(false);
    setLoadsDataLoaded(false);
    setPayoutDataLoaded(false);

    if (activeScreen === "payout") {
      setStartDate("2024-05-26T04:00:00.000Z");
      setEndDate("2028-08-26T04:00:00.000Z");
    } else if (selectedWeek.start && selectedWeek.end) {
      setStartDate(DateTime.fromISO(selectedWeek.start).toUTC().toISO());
      setEndDate(DateTime.fromISO(selectedWeek.end).toUTC().toISO());
    }

    fetchData();
  }, [selectedWeek, activeScreen, fetchData]);

  const [filters, setFilters] = useState({
    selectedFilter,
    driverFilter: activeScreen !== "loads" ? "" : driverFilter,
    unpaidFilter: activeScreen === "loads" ? unpaidFilter : false,
    missingInvoiceFilter:
      activeScreen === "loads" ? missingInvoiceFilter : false
  });

  useEffect(() => {
    setFilters({
      driverFilter: activeScreen !== "loads" ? "" : driverFilter,
      selectedFilter,
      unpaidFilter: activeScreen === "loads" ? unpaidFilter : false,
      missingInvoiceFilter:
        activeScreen === "loads" ? missingInvoiceFilter : false
    });
  }, [
    data,
    driverFilter,
    selectedFilter,
    unpaidFilter,
    missingInvoiceFilter,
    activeScreen
  ]);

  const filteredData = filterLoads(data, filters);

  return (
    <View className="loads-container">
      <View className="loads-header-container">
        <View style={{ flexDirection: "row" }}>
          <Heading className="header-text">Accounting</Heading>
          {sub && selectedCycle && (
            <>
              <SubButton
                onClick={() => {
                  setSub(false);
                  setSelectedCycle(null);
                }}
                title="Go Back"
                active={false}
              />
              <Text>{selectedCycle}</Text>
            </>
          )}
        </View>
        <View className="loads-filter-container">
          <View style={{ display: "flex" }}>
            <input
              type="checkbox"
              className="blue-checkbox"
              checked={selectedFilter === "owners"}
              onChange={() =>
                setSelectedFilter((prev) =>
                  prev === "owners" ? null : "owners"
                )
              }
            />{" "}
            Owners
            <input
              type="checkbox"
              className="blue-checkbox"
              checked={selectedFilter === "companyDrivers"}
              onChange={() =>
                setSelectedFilter((prev) =>
                  prev === "companyDrivers" ? null : "companyDrivers"
                )
              }
            />{" "}
            Company Drivers
          </View>
          {activeScreen === "loads" && (
            <>
              <View style={{ display: "flex" }}>
                <input
                  type="checkbox"
                  className="yellow-checkbox"
                  onChange={(e) => setUnpaidFilter(e.target.checked)}
                />{" "}
                Unpaid
                <input
                  type="checkbox"
                  className="yellow-checkbox"
                  onChange={(e) => setMissingInvoiceFilter(e.target.checked)}
                />{" "}
                Missing invoice
              </View>
              <View style={{ display: "flex" }}>
                <Autocomplete
                  label="Filter by driver"
                  options={driverFilterOptions}
                  placeholder="Filter by driver"
                  size="small"
                  variation="quiet"
                  value={driverFilter}
                  onChange={(event) => {
                    setDriverFilter(event.target.value);
                  }}
                  onClear={() => {
                    setDriverFilter("");
                  }}
                  onSelect={(option) => {
                    const { label } = option;
                    setDriverFilter(label);
                  }}
                />
              </View>
            </>
          )}
          <View style={{ display: "flex" }}>
            <SubButton
              onClick={() => {
                setActiveScreen("summary");
              }}
              title="Summary"
              active={activeScreen === "summary"}
            />
            <SubButton
              onClick={() => {
                setActiveScreen("loads");
              }}
              title="Loads Only"
              active={activeScreen === "loads"}
            />
            <SubButton
              onClick={() => {
                setActiveScreen("payout");
              }}
              title="Payout Cycle"
              active={activeScreen === "payout"}
            />
          </View>
          <View>
            <WeekSelector
              isDisabled={activeScreen === "payout"}
              selectedWeek={selectedWeek}
              setSelectedWeek={setSelectedWeek}
              selectedMonthYear={selectedMonthYear}
              setSelectedMonthYear={setSelectedMonthYear}
            />
          </View>
        </View>
      </View>
      <View className="table-container">
        <Flex direction="column">
          {activeScreen === "summary" && summaryDataLoaded && (
            <AccountantSummary data={filteredData} />
          )}
          {activeScreen === "loads" && loadsDataLoaded && (
            <AccountantLoads data={filteredData} setData={setData} />
          )}
          {activeScreen === "payout" && payoutDataLoaded && (
            <AccountantPayout
              data={data}
              setData={setData}
              sub={sub}
              setSub={setSub}
              filters={filters}
              setSelectedCycle={setSelectedCycle}
            />
          )}
        </Flex>
      </View>
    </View>
  );
};

export default AccountantScreen;
