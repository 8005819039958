import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode
} from "react";
import { jwtDecode } from "jwt-decode";

interface UserJWT {
  id: string;
  roles: string[];
  exp: number;
  iat: number;
}

interface UserContextProps {
  user: UserJWT | null;
  setUser: React.Dispatch<React.SetStateAction<UserJWT | null>>;
  loading: boolean;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const placeholderUser: UserJWT = {
  id: "",
  roles: [],
  iat: 0,
  exp: 0
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserJWT | null>(placeholderUser);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded: UserJWT = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          localStorage.setItem("token", JSON.stringify(placeholderUser));
        } else {
          setUser(decoded);
        }
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.setItem("token", JSON.stringify(placeholderUser));
      }
    }
    setLoading(false);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
