import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { View } from "@aws-amplify/ui-react";

import { useUser } from "./context/userContext";
import { Sidebar } from "./components/Sidebar";
import { Header } from "./components/Header";
import "./app.scss";

function App() {
  const [visibleSidebar, setVisibleSidebar] = useState(true);
  return (
    <View className="container">
      <Sidebar
        visibleSidebar={visibleSidebar}
        setVisibleSidebar={setVisibleSidebar}
      />
      <View
        className={`section-container ${
          visibleSidebar ? "section-container-small" : ""
        }`}
      >
        <Header />
        <View className="main-container">
          <Outlet />
        </View>
      </View>
    </View>
  );
}

export default App;
