import {
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Image
} from "@aws-amplify/ui-react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";

import { SubTable } from "../SubTable";
import ArrowDownIcon from "../../../assets/images/arrow-down-icon.svg";
import ArrowUpIcon from "../../../assets/images/arrow-up-icon.svg";
import "./table-style.scss";
import {
  useCreateCycle,
  useUpdateCycleFields
} from "../../../services/cycleService";

export const Table = forwardRef(({ data, setData, columns }, ref) => {
  const [sorting, setSorting] = useState([]);

  // Mutation for updating cycle fields
  const updateCycleFieldsMutation = useUpdateCycleFields();

  // Mutation for creating a new cycle
  const createCycleMutation = useCreateCycle();

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getRowCanExpand: () => true,
    sortDescFirst: true,
    enableSorting: true,
    enableMultiSort: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: async (rowIndex, columnId, value) => {
        const oldData = [...data]; // Clone current data to work with

        // Handle updates and potential new cycle creation
        if (oldData[rowIndex][columnId] !== value) {
          const submitValue = ["diesel", "toll", "expenses"].includes(columnId)
            ? parseFloat(value)
            : value;

          const updatedCycle = {
            ...oldData[rowIndex].cycleModel,
            [columnId]: submitValue
          };

          if (oldData[rowIndex].cycleModel) {
            // Update existing cycle
            await updateCycleFieldsMutation.mutateAsync({
              cycle: updatedCycle.cycle,
              cycleId: updatedCycle._id,
              toll: updatedCycle.toll,
              diesel: updatedCycle.diesel,
              expenses: updatedCycle.expenses
            });

            // Update the local state directly after the mutation
            oldData[rowIndex] = {
              ...oldData[rowIndex],
              cycleModel: updatedCycle,
              [columnId]: submitValue
            };
          } else {
            // Create a new cycle
            const newCycle = {
              cycle: oldData[rowIndex].formattedCycle,
              driver: oldData[rowIndex].driverId,
              owner: oldData[rowIndex].ownerId,
              toll: 0,
              diesel: 0,
              expenses: 0,
              paymentStatus: false,
              [columnId]: submitValue
            };

            try {
              // Handle creating cycle and wait for the response
              const createdCycle =
                await createCycleMutation.mutateAsync(newCycle);

              // Update the row with the new cycle data, including the _id
              oldData[rowIndex] = {
                ...oldData[rowIndex],
                cycleModel: {
                  ...newCycle,
                  _id: createdCycle._id // Update with the actual ID
                },
                [columnId]: submitValue
              };
            } catch (error) {
              console.error("Error creating cycle:", error);
              // Optionally handle error feedback here
              return; // Exit the function on error
            }
          }
        }

        // Update the data once all processing is complete
        setData(oldData);
      }
    },
    debugTable: true
  });

  useImperativeHandle(
    ref,
    () => ({
      closeRows: () => table.toggleAllRowsExpanded(false)
    }),
    [table]
  );

  const getTableItemColorClassName = (index) => {
    const colorClasses = [
      "", // #
      "", // Default
      "", // index 2
      "table-item-black", // index 3
      "table-item-dark-blue", // index 4
      "table-item-blue" // index 5
    ];
    return colorClasses[index] || "";
  };

  const getArrowIconStyle = (index) => {
    const styles = [
      {
        filter:
          "brightness(0) saturate(100%) invert(43%) sepia(40%) saturate(208%) hue-rotate(181deg) brightness(91%) contrast(87%)",
        opacity: 1
      }, // Default
      {},
      {
        filter:
          "brightness(0) saturate(100%) invert(13%) sepia(19%) saturate(13%) hue-rotate(340deg) brightness(101%) contrast(88%)",
        opacity: 1
      }, // index 2
      {
        filter:
          "brightness(0) saturate(100%) invert(27%) sepia(58%) saturate(540%) hue-rotate(177deg) brightness(90%) contrast(92%)",
        opacity: 0.7
      }, // index 3
      {
        filter:
          "brightness(0) saturate(100%) invert(29%) sepia(86%) saturate(1201%) hue-rotate(195deg) brightness(98%) contrast(95%)",
        opacity: 0.55
      } // index 4
    ];
    return styles[index] || styles[0];
  };

  return (
    <View>
      <ATable variation="striped" width="100%">
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                  {!header.isPlaceholder && (
                    <View
                      className={`table-header-item ${
                        header.column.getCanSort()
                          ? "cursor-pointer select-none"
                          : ""
                      } ${getTableItemColorClassName(header.index)}`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <Image
                            alt="arrow-up-icon"
                            src={ArrowUpIcon}
                            width="16px"
                            style={getArrowIconStyle(header.index)}
                          />
                        ),
                        desc: (
                          <Image
                            alt="arrow-down-icon"
                            src={ArrowDownIcon}
                            width="16px"
                            style={getArrowIconStyle(header.index)}
                          />
                        )
                      }[header.column.getNextSortingOrder()] ?? null}
                    </View>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <TableRow style={{ cursor: "pointer" }}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    onClick={
                      ["diesel", "toll", "expenses"].includes(cell.column.id)
                        ? () => {}
                        : row.getToggleExpandedHandler()
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
              {row.getIsExpanded() && (
                <TableRow className="sub-table-background">
                  <TableCell colSpan={row.getVisibleCells().length}>
                    <SubTable row={row} />
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </ATable>
    </View>
  );
});
