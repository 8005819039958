import { useQuery } from "react-query";

import apiClient from "../api/axios";

const fetchSettings = async (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return [];
  const { data } = await apiClient.get("/settings");
  return data;
};

export const useSettings = (startDate: string, endDate: string) => {
  return useQuery(
    ["settings", startDate, endDate],
    () => fetchSettings(startDate, endDate),
    {
      enabled: !!startDate && !!endDate
    }
  );
};
