import { useForm } from "react-hook-form";
import {
  Input,
  View,
  PasswordField,
  Button,
  SelectField
} from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import "./driver-style.scss";

export const AddDriverScreen = () => {
  const [owners, setOwners] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    const post = { ...data, username: data.email };
    fetch(
      "https://psrtoi0hyh.execute-api.us-east-2.amazonaws.com/prod/driver",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(post)
      }
    )
      .then(async (res) => {
        const re = await res.json();
        if (re.success) {
          alert("success");
        } else {
          throw new Error("Fail");
        }
      })
      .catch((e) => {
        alert("erorr!");
      });
  };

  useEffect(() => {
    fetch(
      "https://7s7elha7al.execute-api.us-east-2.amazonaws.com/prod/owners"
    ).then(async (res) => {
      const re = await res.json();
      if (re.response.length > 0) {
        setOwners(re.response);
      }
    });
  }, []);

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <form onSubmit={handleSubmit(onSubmit)}>
      <View className="add-driver-container">
        <View className="form-block">
          {/* include validation with required or other standard HTML validation rules */}
          <View>
            <Input
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {/* errors will return when field validation fails  */}
            {errors.name && (
              <span className="error-text">This field is required</span>
            )}
          </View>
          {/* <Input placeholder="Phone" {...register("phone", { required: true })} /> */}
          {/* errors will return when field validation fails  */}
          {/* {errors.phone && <span>This field is required</span>} */}
          <View>
            <Input
              type="email"
              placeholder="Email"
              {...register("email", { required: true })}
            />
            {/* errors will return when field validation fails  */}
            {errors.email && (
              <span className="error-text">This field is required</span>
            )}
          </View>
          <View>
            <PasswordField
              autoComplete="new-password"
              label="Password"
              labelHidden={true}
              placeholder="Password"
              {...register("password", { required: true })}
            />
            {/* errors will return when field validation fails  */}
            {errors.password && (
              <span className="error-text">This field is required</span>
            )}
          </View>
          <View>
            <SelectField
              label="Owner"
              descriptiveText="Select owner"
              labelHidden={false}
              {...register("owner", { required: true })}
            >
              {owners.map((option) => (
                <option value={option.sub}>{option.email}</option>
              ))}
            </SelectField>
            {/* errors will return when field validation fails  */}
            {errors.owner && (
              <span className="error-text">This field is required</span>
            )}
          </View>

          <Button
            className="submit-button"
            variation="primary"
            loadingText="Submitting"
            type="submit"
          >
            Submit
          </Button>
        </View>
      </View>
    </form>
  );
};
