import { useQuery, useMutation, useQueryClient } from "react-query";

import apiClient from "../api/axios";

// Function to fetch cycles
const fetchCycles = async (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return [];
  const { data } = await apiClient.get("/cycles", {
    params: { startDate, endDate }
  });
  return data;
};

// React Query hook for fetching cycles
const useCycles = (startDate: string, endDate: string) => {
  return useQuery(
    ["cycles", startDate, endDate],
    () => fetchCycles(startDate, endDate),
    {
      enabled: !!startDate && !!endDate // Enable query only if both dates are present
    }
  );
};

// Function to update cycle fields via API
const updateCycleFields = async ({
  cycle,
  cycleId,
  toll,
  diesel,
  expenses,
  paymentStatus
}: {
  cycle: string;
  cycleId: string;
  toll: number;
  diesel: number;
  expenses: number;
  paymentStatus: boolean;
}) => {
  const { data } = await apiClient.patch(`/cycles/${cycleId}`, {
    cycle,
    toll,
    diesel,
    expenses,
    paymentStatus
  });
  return data; // Return updated cycle data
};

// React Query mutation hook for updating cycle fields
const useUpdateCycleFields = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCycleFields, {
    onSuccess: () => {
      // Invalidate and refetch cycles after successful update
      queryClient.invalidateQueries("cycles");
    },
    onError: (error) => {
      console.error("Error updating cycle fields:", error);
    }
  });
};

// Function to create a cycle via API
const createCycle = async (cycleData: {
  cycle: string;
  driver: string;
  owner: string;
  toll: number;
  diesel: number;
  expenses: number;
  paymentStatus: boolean;
}) => {
  const { data } = await apiClient.post("/cycles", cycleData);
  return data; // Return created cycle data
};

// React Query mutation hook for creating a cycle
const useCreateCycle = () => {
  const queryClient = useQueryClient(); // Get query client for invalidation

  return useMutation(createCycle, {
    onSuccess: () => {
      // Invalidate and refetch cycles after successful creation
      queryClient.invalidateQueries("cycles");
    },
    onError: (error) => {
      console.error("Error creating cycle:", error);
    }
  });
};

// Export the hooks for use in components
export { useCycles, useUpdateCycleFields, useCreateCycle };
