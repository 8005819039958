import { Theme } from "@aws-amplify/ui-react";

export const theme: Theme = {
  name: "my-theme",
  tokens: {
    colors: {
      font: {
        primary: { value: "#757575" }
        // ...
      }
    }
  }
};
