import {
  Autocomplete,
  Flex,
  Heading,
  Image,
  SearchField,
  Text,
  View
} from "@aws-amplify/ui-react";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import { createColumnHelper } from "@tanstack/react-table";

import { Table } from "../../../components/Loads/Table";
import SearchIcon from "../../../assets/images/search-icon.svg";
import { WeekSelector } from "../../../components/WeekSelector";
import { useLoads } from "../../../services/loadService";
import { filterLoads, prepareDispatchTableData } from "../../../utils";
import ArrowsIcon from "../../../assets/images/arrows-icon.svg";
import "./loads-style.scss";

export const DispatchLoadsScreen = () => {
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor("#", {
      header: "#",
      cell: (info) => {
        return (
          <Text className="table-row-text index-text">{+info.row.id + 1}</Text>
        );
      }
    }),
    columnHelper.accessor("driverName", {
      header: "Driver",
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("gross", {
      header: "Gross",
      cell: (info) => `$${info.getValue().toFixed(2)}`
    }),
    columnHelper.accessor("destination", {
      header: "Destination",
      cell: (info) => {
        const destinationTexts = info.getValue().split("$sep");
        return (
          <Text className="destination-item">
            <Text className="destination-first">
              {destinationTexts[0] || "-"}
            </Text>
            <Image alt="arrow" src={ArrowsIcon} width="24" />
            <Text className="destination-last">
              {destinationTexts[1] || "-"}
            </Text>
          </Text>
        );
      }
    })
  ];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedMonthYear, setSelectedMonthYear] = useState({});
  const [selectedWeek, setSelectedWeek] = useState({});
  const [search, setSearch] = useState("");

  const [dispatchFilter, setDispatchFilter] = useState("");
  const [dispatchFilterOptions, setDispatchFilterOptions] = useState([]);

  const {
    data: loadsData,
    error,
    isLoading
  } = useLoads(startDate, endDate, search);

  const [data, setData] = useState([]);

  const searchOnClear = () => {
    setSearch("");
  };

  const searchOnChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (selectedWeek !== "") {
      setStartDate(DateTime.fromISO(selectedWeek.start).toUTC().toISO());
      setEndDate(DateTime.fromISO(selectedWeek.end).toUTC().toISO());
    }
  }, [selectedWeek]);

  useEffect(() => {
    if (loadsData) {
      const prepared =
        loadsData.length > 0 ? prepareDispatchTableData(loadsData) : [];
      setData(prepared);
      setDispatchFilterOptions(
        Array.from(new Set(prepared.map((load) => load.dispatch))).map(
          (dispatchName) => ({
            label: dispatchName,
            id: dispatchName
          })
        ) ?? []
      );
    } else {
      setData([]);
    }
  }, [loadsData]);

  const tableRef = useRef(null);
  const [filters, setFilters] = useState({
    dispatchFilter
  });

  useEffect(() => {
    setFilters({
      dispatchFilter
    });
  }, [data, dispatchFilter]);
  const filteredData = filterLoads(data, filters);
  return (
    <View className="loads-container">
      <View className="loads-header-container">
        <View>
          <Heading className="header-text">Loads</Heading>
        </View>
        <View className="loads-filter-container">
          <View style={{ display: "flex" }}>
            <Autocomplete
              label="Filter by dispatch"
              options={dispatchFilterOptions}
              placeholder="Filter by dispatch"
              size="small"
              variation="quiet"
              value={dispatchFilter}
              onChange={(event) => {
                setDispatchFilter(event.target.value);
              }}
              onClear={() => {
                setDispatchFilter("");
              }}
              onSelect={(option) => {
                const { label } = option;
                setDispatchFilter(label);
              }}
            />
          </View>
          <View>
            <SearchField
              className="loads-search-input"
              placeholder="Search by invoice number"
              hasSearchButton={false}
              hasSearchIcon={true}
              onChange={searchOnChange}
              onClear={searchOnClear}
              innerStartComponent={
                <View style={{ padding: 10 }}>
                  <Image alt="search-icon" src={SearchIcon} width="15.32px" />
                </View>
              }
              labelHidden
            />
          </View>
          <View>
            <WeekSelector
              selectedWeek={selectedWeek}
              setSelectedWeek={setSelectedWeek}
              selectedMonthYear={selectedMonthYear}
              setSelectedMonthYear={setSelectedMonthYear}
            />
          </View>
        </View>
      </View>
      <View className="table-container">
        <Flex direction="column">
          <Table ref={tableRef} data={filteredData} columns={columns} />
        </Flex>
      </View>
    </View>
  );
};
