import {
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  Text,
  TableCell
} from "@aws-amplify/ui-react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Fragment, forwardRef, useImperativeHandle } from "react";

import { SubTable } from "../SubTable";
import "./loads-table-style.scss";

export const Table = forwardRef(({ data, columns }, ref) => {
  const table = useReactTable({
    data,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  });
  useImperativeHandle(ref, () => {
    return {
      closeRows: () => table.toggleAllRowsExpanded(false)
    };
  }, [table]);
  return (
    <View>
      <View />
      <ATable
        width="100%"
        highlightOnHover={false}
        // variation="striped"
        className="loads-table-new"
      >
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                return (
                  <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <Text
                        className={`table-header-item ${index === 2 ? "table-item-dark-blue" : ""}`}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <TableRow
                  onClick={row.getToggleExpandedHandler()}
                  style={{ cursor: "pointer" }}
                >
                  {/* first row is a normal row */}
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <TableCell
                        key={cell.id}
                        className={`dispatch-loads-parent-${cell.column.id} `}
                      >
                        <Text
                          className={`table-body-item ${index === 1 ? "table-item-green" : index === 2 ? "table-item-blue" : ""}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Text>
                      </TableCell>
                    );
                  })}
                </TableRow>
                {row.getIsExpanded() && (
                  <TableRow className="sub-table-background">
                    {/* 2nd row is a custom 1 cell row */}
                    <TableCell colSpan={row.getVisibleCells().length}>
                      <SubTable row={row} />
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </ATable>
      <View />
      {/* <View>{table.getRowModel().rows.length} Rows</View> */}
    </View>
  );
});
