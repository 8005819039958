// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountant-summary-table-body td:nth-child(1) .table-body-item {
  color: #667085 !important;
  font-size: 16px;
  font-weight: 400;
}
.accountant-summary-table-body td:nth-child(2) .table-body-item {
  color: #2e2e2e !important;
  font-size: 16x;
  font-weight: 400;
}
.accountant-summary-table-body td:nth-child(3) .table-body-item {
  color: rgba(47, 77, 122, 0.6980392157) !important;
  font-size: 16px;
  font-weight: 400;
}
.accountant-summary-table-body td:nth-child(4) .table-body-item {
  color: #667085 !important;
  font-size: 16px;
  font-weight: 400;
}
.accountant-summary-table-body td:nth-child(5) .table-body-item {
  color: #949494 !important;
  font-size: 16px;
  font-weight: 400;
}
.accountant-summary-table-body td:nth-child(6) .table-body-item {
  color: #849f84 !important;
  font-size: 16px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/Accountant/Summary/summary-table-style.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAEE;EACE,iDAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".accountant-summary-table-body {\n  td:nth-child(1) .table-body-item {\n    color: #667085 !important;\n    font-size: 16px;\n    font-weight: 400;\n  }\n  td:nth-child(2) .table-body-item {\n    color: #2e2e2e !important;\n    font-size: 16x;\n    font-weight: 400;\n  }\n  td:nth-child(3) .table-body-item {\n    color: #2f4d7ab2 !important;\n    font-size: 16px;\n    font-weight: 400;\n  }\n  td:nth-child(4) .table-body-item {\n    color: #667085 !important;\n    font-size: 16px;\n    font-weight: 400;\n  }\n  td:nth-child(5) .table-body-item {\n    color: #949494 !important;\n    font-size: 16px;\n    font-weight: 400;\n  }\n  td:nth-child(6) .table-body-item {\n    color: #849f84 !important;\n    font-size: 16px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
