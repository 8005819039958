import { useQuery, useMutation, useQueryClient } from "react-query";

import apiClient from "../api/axios";

// Fetch loads API call
const fetchLoads = async (
  startDate?: string,
  endDate?: string,
  invoiceNumber?: string
) => {
  if (!startDate && !endDate && !invoiceNumber) {
    throw new Error(
      "Please provide either both startDate and endDate, or invoiceNumber"
    );
  }

  const params: any = {};
  if (startDate && endDate) {
    params.startDate = startDate;
    params.endDate = endDate;
  }
  if (invoiceNumber) {
    params.invoiceNumber = invoiceNumber;
  }

  const { data } = await apiClient.get("/loads", { params });
  return data;
};

// React Query hook for fetching loads
const useLoads = (
  startDate?: string,
  endDate?: string,
  invoiceNumber?: string
) => {
  return useQuery(
    ["loads", startDate, endDate, invoiceNumber],
    () => fetchLoads(startDate, endDate, invoiceNumber),
    {
      enabled: (!!startDate && !!endDate) || !!invoiceNumber
    }
  );
};

// Function to update load fields via API
const updateLoadFields = async ({
  loadId,
  invoiced,
  paymentStatus,
  note,
  broker
}: {
  loadId: string;
  invoiced: string;
  paymentStatus: string;
  note: string;
  broker: string;
}) => {
  const { data } = await apiClient.patch(`/loads/${loadId}`, {
    invoiced,
    paymentStatus,
    note,
    broker
  });
  return data;
};

// React Query mutation hook for updating load fields
const useUpdateLoadFields = () => {
  const queryClient = useQueryClient();

  return useMutation(updateLoadFields, {
    onSuccess: () => {
      // Invalidate and refetch loads after successful update
      queryClient.invalidateQueries("loads");
    },
    onError: (error) => {
      console.error("Error updating load fields:", error);
    }
  });
};

export { useLoads, useUpdateLoadFields };
