// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountant-loads-table-body td:nth-child(1) {
  color: #667085 !important;
  font-size: 14px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/Accountant/Loads/loads-table-style.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".accountant-loads-table-body {\n  td:nth-child(1) {\n    color: #667085 !important;\n    font-size: 14px;\n    font-weight: 400;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
