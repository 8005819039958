// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th,
td {
  border-right-width: 0 !important;
  border-left-width: 0 !important;
  border-bottom: 1px solid #474343;
}

.table-header-item {
  color: #667085;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
}

.table-item-dark-blue {
  color: #ffffff !important;
}

.table-body-item {
  color: #667085 !important;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  white-space: nowrap;
}

.table-item-blue {
  color: #ffffff !important;
}

.table-item-green {
  color: #d2d2d2 !important;
}

.sub-table-background {
  background-color: #fafafa;
}
.sub-table-background > td {
  padding: 12px 0 !important;
}
.sub-table-background th {
  border-top: 0 !important;
}
.sub-table-background th:first-of-type,
.sub-table-background td:first-of-type {
  padding-left: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/Loads/Table/loads-table-style.scss"],"names":[],"mappings":"AAAA;;EAEE,gCAAA;EACA,+BAAA;EACA,gCAAA;AACF;;AAEA;EACE,cAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;AACE;EACE,0BAAA;AACJ;AAEE;EACE,wBAAA;AAAJ;AAGE;;EAEE,kBAAA;AADJ","sourcesContent":["th,\ntd {\n  border-right-width: 0 !important;\n  border-left-width: 0 !important;\n  border-bottom: 1px solid #474343;\n}\n\n.table-header-item {\n  color: #667085;\n  font-family: \"Inter\", sans-serif;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 18px;\n  white-space: nowrap;\n}\n\n.table-item-dark-blue {\n  color: #ffffff !important;\n}\n\n.table-body-item {\n  color: #667085 !important;\n  font-family: \"Inter\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 20px;\n  white-space: nowrap;\n}\n\n.table-item-blue {\n  color: #ffffff !important;\n}\n\n.table-item-green {\n  color: #d2d2d2 !important;\n}\n\n.sub-table-background {\n  background-color: #fafafa;\n\n  & > td {\n    padding: 12px 0 !important;\n  }\n\n  th {\n    border-top: 0 !important;\n  }\n\n  th:first-of-type,\n  td:first-of-type {\n    padding-left: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
