import ReactDOM from "react-dom/client";
import "./reset.css";
import "./global.scss";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from "react-router-dom";
import { ThemeProvider } from "@aws-amplify/ui-react";
import { QueryClientProvider } from "react-query";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DispatchLoadsScreen } from "./screens/dispatch/Loads";
import "@aws-amplify/ui-react/styles.css";
import { OwnerFleetScreen } from "./screens/owner/Fleet";
import { theme } from "./theme";
import { AddDispatchScreen } from "./screens/headDispatch/AddDispatch";
import { AddDriverDispatchScreen } from "./screens/headDispatch/AddDriverDispatch";
import { AddDriverScreen } from "./screens/dispatch/AddDriver";
import { AccountantScreen } from "./screens/Accountant";
import { AddDriverHeadScreen } from "./screens/headDispatch/AddDriver";
import Setup2FA from "./screens/auth/Setup2FA";
import Register from "./screens/auth/Register";
import Login from "./screens/auth/Login";
import queryClient from "./queryClient";
import Front from "./Front";
import { UserProvider, useUser } from "./context/userContext";
import { useEffect } from "react";
import useLogout from "./hooks/useLogout";

const NavigationHandler = () => {
  const navigate = useNavigate();
  const { user, loading } = useUser();
  const { isLoggingOut } = useLogout();
  const isLoggedIn = user?.roles?.length > 0;
  //const is2FAEnabled = user?.is2FAEnabled;

  useEffect(() => {
    if (!isLoggedIn && !loading && !isLoggingOut) {
      navigate("/login");
    }
    // else if (!is2FAEnabled) {
    //   navigate("/setup-2fa");
    // }
  }, [user, loading, navigate, isLoggingOut]);

  return null;
};

const AppRouter = () => {
  const { user, loading } = useUser();
  const isLoggedIn = user?.roles?.length > 0;
  //const is2FAEnabled = user?.is2FAEnabled;

  if (loading) return <div>Loading...</div>;

  const router = createBrowserRouter([
    {
      path: "/",
      element: isLoggedIn ? <App /> : <Front />,
      children: isLoggedIn
        ? [
            { path: "dispatch", element: <DispatchLoadsScreen /> },
            { path: "accountant", element: <AccountantScreen /> },
            { path: "addDispatch", element: <AddDispatchScreen /> },
            { path: "addDriverDispatch", element: <AddDriverDispatchScreen /> },
            { path: "addDriverHeadDispatch", element: <AddDriverHeadScreen /> },
            { path: "addDriver", element: <AddDriverScreen /> },
            { path: "owner", element: <OwnerFleetScreen /> }
          ]
        : [
            { path: "2fa-setup", element: <Setup2FA /> },
            { path: "register", element: <Register /> },
            { path: "login", element: <Login /> }
          ]
    }
  ]);

  return (
    <RouterProvider router={router}>
      <NavigationHandler />
    </RouterProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <AppRouter />
      </UserProvider>
    </QueryClientProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
