import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Input,
  PasswordField,
  Button,
  View,
  SelectField
} from "@aws-amplify/ui-react";

import apiClient from "../../../api/axios";
import "../../headDispatch/AddDispatch/dispatch-style.scss";

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiClient.post("/register", { email, password, role });
      navigate("/login");
    } catch (error) {
      console.error("Registration error", error);
    }
  };

  return (
    <form onSubmit={handleRegister}>
      <View className="add-dispatch-container">
        <View className="form-block">
          <h2>Register</h2>
          <View>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </View>
          <View>
            <PasswordField
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </View>
          <View>
            <SelectField
              placeholder="Select role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
            >
              <option value="">Select role</option>
              <option value="superAdmin">Super Admin</option>
              <option value="dispatch">Dispatch</option>
              <option value="headDispatch">Head Dispatch</option>
              <option value="driver">Driver</option>
              <option value="owner">Owner</option>
              <option value="accountant">Accountant</option>
            </SelectField>
          </View>
          <Button
            className="submit-button"
            variation="primary"
            loadingText="Registering"
            type="submit"
          >
            Register
          </Button>
        </View>
      </View>
    </form>
  );
};

export default Register;
