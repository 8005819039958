import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Image,
  TableFoot
} from "@aws-amplify/ui-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";

import { capitalizeFirstLetter, filterLoads } from "../../../utils";
import ArrowUpIcon from "../../../assets/images/arrow-up-icon.svg";
import ArrowDownIcon from "../../../assets/images/arrow-down-icon.svg";
import { AccountantPayoutSub } from "./PayoutSub";
import "./loads-table-style.scss";

const COLUMN_HEADERS = {
  cycle: "Payment Cycle",
  on: "On Duty",
  off: "Off Duty",
  pay: "Pay",
  paid: "Paid Invoices",
  unpaid: "Unpaid Invoices",
  cyclePaymentStatus: "Cycle Payment Status"
};

const getColumnHeader = (item) =>
  COLUMN_HEADERS[item] || capitalizeFirstLetter(item);

const getColumnCell = (item, info) => {
  if (["pay", "paid", "unpaid"].includes(item)) {
    const className = item === "pay" ? "payAmount-text" : "basicTable-text";
    if (item === "unpaid") {
      return info.getValue() === 0 ? (
        <Text className="paidInFull-text">FULL</Text>
      ) : (
        `$${info.getValue().toFixed(2)}`
      );
    }
    return (
      <Text className={className}>{`$${info.getValue().toFixed(2)}`}</Text>
    );
  } else if (item === "off") {
    return (
      <Text
        className={info.getValue() <= 0 ? "basicTable-text" : "offDuty-text"}
      >
        {info.getValue()}
      </Text>
    );
  } else if (item === "cyclePaymentStatus") {
    const val = info.getValue();
    const className =
      val === "Paid"
        ? "cyclePaid-text"
        : val === "Upcoming"
          ? "cycleUpcoming-text"
          : val === "Friday Pay Day"
            ? "cycleFriday-text"
            : "";
    return <Text className={className}>{val}</Text>;
  } else if (item === "#") {
    return (
      <Text className="table-row-text index-text">{+info.row.id + 1}</Text>
    );
  } else {
    return info.getValue();
  }
};
let table;
const getColumnFooter = (item) => {
  const total = table
    .getFilteredRowModel()
    .rows.reduce((sum, row) => sum + row.getValue(item), 0);

  if (["pay", "paid", "unpaid"].includes(item)) {
    return <Text>{`$${total.toFixed(2)}`}</Text>;
  } else if (["on", "off"].includes(item)) {
    return <Text>{total}</Text>;
  } else {
    return "";
  }
};

export const AccountantPayout = ({
  data,
  setData,
  sub,
  setSub,
  filters,
  setSelectedCycle
}) => {
  const [sorting, setSorting] = useState([]);
  const [lastKnownSubId, setLastKnownSubId] = useState(null); // New state for last known _id

  const columnHelper = createColumnHelper();
  const columns = [
    "#",
    "cycle",
    "on",
    "off",
    "pay",
    "paid",
    "unpaid",
    "cyclePaymentStatus"
  ].map((item) =>
    columnHelper.accessor(item, {
      header: () => getColumnHeader(item),
      cell: (info) => getColumnCell(item, info),
      footer: () => getColumnFooter(item) // Pass the item to getColumnFooter
    })
  );

  table = useReactTable({
    data,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  });

  useEffect(() => {
    console.log(lastKnownSubId, "sdgdsfgdf");
    if (sub || lastKnownSubId) {
      const index = data.findIndex((item) => {
        const p = item.sub.find(
          (subItem) =>
            sub.sub.some((s) => s._id === subItem._id) ||
            subItem._id === lastKnownSubId
        );
        return !!p;
      });

      if (index !== -1) {
        const filteredSub = filterLoads(data[index].sub || [], filters);
        setSub({ ...data[index].sub, sub: filteredSub });
      }
    }
  }, [filters, setSub, lastKnownSubId, data]);

  return (
    <View className="stickyFooter-table-container">
      {!sub ? (
        <ATable
          highlightOnHover
          variation="striped"
          className="stickyFooter-table"
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                    {!header.isPlaceholder && (
                      <View
                        className={`table-header-item ${
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }`}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getNextSortingOrder() === "asc" ? (
                          <Image
                            alt="arrow-up-icon"
                            src={ArrowUpIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        ) : header.column.getNextSortingOrder() === "desc" ? (
                          <Image
                            alt="arrow-down-icon"
                            src={ArrowDownIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        ) : null}
                      </View>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className="accountant-loads-table-body">
            {table.getRowModel().rows.map((row, index) => (
              <TableRow
                key={row.id}
                onClick={() => {
                  setLastKnownSubId(row.original.sub[0]._id); // Update the last known _id
                  setSelectedCycle(row.original.cycle);
                  setSub({
                    ...row.original,
                    sub: filterLoads(row.original.sub || [], filters)
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    className={`accountant-loads-${
                      cell.column.id
                    } loads-sub-table-row-text ${
                      table.getRowModel().rows.length - 1 === index
                        ? "none-border"
                        : ""
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFoot className="stickyFooter-tfoot">
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow>
                {footerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    <Text>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFoot>
        </ATable>
      ) : (
        <AccountantPayoutSub
          data={sub}
          setData={(updatedSub) => {
            const index = data.findIndex((item) =>
              item.sub.find((subItem) => subItem._id === updatedSub._id)
            );
            if (index !== -1) {
              const updatedData = data.map((item, i) => {
                if (i === index) {
                  const requiredSubIndex = item.sub.findIndex(
                    (subItem) => subItem._id === updatedSub._id
                  );
                  item.sub[requiredSubIndex] = updatedSub;
                }
                return item;
              });
              setData(updatedData);
            }
          }}
        />
      )}
    </View>
  );
};
