import { useForm } from "react-hook-form";
import { Input, Button, View } from "@aws-amplify/ui-react";
import { useState, useEffect } from "react";
import apiClient from "../../../api/axios";
import "./driver-head-style.scss";

export const AddDriverHeadScreen = () => {
  const [owners, setOwners] = useState([]);
  const [dispatches, setDispatches] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    const post = { ...data, username: data.email };
    apiClient
      .post("/dispatch/processRegisterDriver", post)
      .then((res) => {
        if (res.data.success) {
          alert("success");
        } else {
          throw new Error("Fail");
        }
      })
      .catch((e) => {
        alert("error!");
        console.log(e);
      });
  };

  useEffect(() => {
    apiClient
      .get("/dispatch/owners")
      .then((res) => {
        if (res.data.length > 0) {
          setOwners(res.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching owners", e);
      });

    apiClient
      .get("/dispatch/dispatches")
      .then((res) => {
        if (res.data.length > 0) {
          setDispatches(res.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching dispatchers", e);
      });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <View className="add-driver-head-dispatch-container">
        <View className="form-block">
          <View>
            <Input
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors.name && <span>This field is required</span>}
          </View>

          <View>
            <Input
              type="email"
              placeholder="Email"
              {...register("email", { required: true })}
            />
            {errors.email && <span>This field is required</span>}
          </View>

          <Button
            className="submit-button"
            variation="primary"
            loadingText="Submitting"
            type="submit"
          >
            Submit
          </Button>
        </View>
      </View>
    </form>
  );
};
