import { View, Text } from "@aws-amplify/ui-react";

export const SubButton = ({
  title = "Button",
  onClick = () => alert("Button Clicked"),
  active = false,
  className = ""
}) => {
  return (
    <View
      className={className}
      style={{
        padding: "7px 15px",
        margin: "0px 10px",
        border: "2px solid #2D2D2D",
        borderRadius: "6px",
        textAlign: "center"
      }}
      onClick={onClick}
    >
      <Text style={{ color: active ? "#000000" : "#A5A5A5" }}>{title}</Text>
    </View>
  );
};
