import {
  Text,
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  SelectField,
  Image
} from "@aws-amplify/ui-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState
} from "@tanstack/react-table";
import { format } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";

import { defaultDateFormat } from "../../../constants";
import { capitalizeFirstLetter } from "../../../utils";
import "./loads-sub-table-style.scss";
import ArrowUpIcon from "../../../assets/images/arrow-up-icon.svg";
import ArrowDownIcon from "../../../assets/images/arrow-down-icon.svg";
import { useUser } from "../../../context/userContext"; // Import the user context
import { useUpdateLoadFields } from "../../../services/loadService"; // Import the mutation hook

export const SubTable = ({ row: parentRow }) => {
  const [data, setData] = useState(parentRow?.original?.loads);
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper();

  const {
    user: { roles }
  } = useUser();
  const updateLoadFieldsMutation = useUpdateLoadFields(); // Initialize the mutation hook

  useEffect(() => {
    setData(parentRow?.original?.loads);
  }, [parentRow]);

  const DefaultColumn = ({
    getValue,
    row: { index },
    column: { id },
    table
  }) => {
    const initialValue = getValue();
    useEffect(() => {
      if (roles?.includes("accountant"))
        setEditing(initialValue ? false : true);
    }, [initialValue]);
    const [value, setValue] = useState(initialValue);
    const [editing, setEditing] = useState(false);
    const onBlur = async () => {
      await table.options.meta?.updateData(index, id, value);
      setEditing(false);
    };
    const fieldRef = useRef<HTMLInputElement | HTMLSelectElement | null>(null);
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      if (editing && value && value.length > 0) fieldRef?.current?.focus();
    }, [editing, value]);
    const Element = editing ? (
      id === "paymentStatus" ? (
        <SelectField
          minWidth={100}
          style={{
            fontSize: 12,
            padding: 3
          }}
          label="Payment status"
          labelHidden={true}
          ref={fieldRef}
          value={value as string}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
        >
          <option value="UNPAID">UNPAID</option>
          <option value="PAID">PAID</option>
          <option value="FACTORING">FACTORING</option>
          <option value="CASH">Cash</option>
          <option value="CHECK">Check</option>
          <option value="MONEY_ORDER">Money Order</option>
          <option value="COMCHEK">Comchek</option>
          <option value="CREDIT_CARD">Credit Card</option>
          <option value="DIRECT_DEPOSIT">Direct Deposit</option>
          <option value="VENMO">Venmo</option>
          <option value="CASHAPP">CashApp</option>
          <option value="USHIP">UShip</option>
          <option value="ZELLE">Zelle</option>
          <option value="SUPERPAY">SuperPay</option>
          <option value="OTHER">Other</option>
        </SelectField>
      ) : (
        <input
          style={{
            maxWidth: 100
          }}
          ref={fieldRef}
          value={value as string}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
        />
      )
    ) : value?.toLowerCase() === "unpaid" ? (
      <Text
        className="loads-sub-table-row-text loads-text-unpaid"
        onDoubleClick={() => {
          if (roles?.includes("accountant")) setEditing(!editing);
        }}
      >
        {value}
      </Text>
    ) : value?.toLowerCase() === "cash" ? (
      <Text
        className="loads-sub-table-row-text loads-text-cash"
        onDoubleClick={() => {
          if (roles?.includes("accountant")) setEditing(!editing);
        }}
      >
        {value}
      </Text>
    ) : (
      <Text
        className="loads-sub-table-row-text"
        onDoubleClick={() => {
          if (roles?.includes("accountant")) setEditing(!editing);
        }}
      >
        {value}
      </Text>
    );
    return <Fragment>{Element}</Fragment>;
  };

  const columns = [];

  [
    "#",
    "date",
    "load",
    "pickup",
    "dropoff",
    "price",
    "payment",
    "paymentStatus",
    "invoiceNumber",
    "invoiceStatus",
    "broker"
  ].forEach((item) => {
    columns.push(
      columnHelper.accessor(item, {
        header: (info) => {
          if (item === "pickup") {
            return "Pick up";
          } else if (item === "dropoff") {
            return "Drop off";
          } else if (item === "invoiceNumber") {
            return "Load ID";
          } else if (item === "payment") {
            return "Method";
          } else if (item === "paymentStatus") {
            return "Payment Status";
          } else if (item === "invoiceStatus") {
            return "Invoice Status";
          } else {
            return capitalizeFirstLetter(info.column.id);
          }
        },
        cell: (info) => {
          if (item === "date") {
            return format(new Date(info.getValue()), defaultDateFormat);
          } else if (item === "price") {
            return `$${info.getValue().toFixed(2)}`;
          } else if (
            ["paymentStatus", "broker", "invoiceStatus"].includes(item)
          ) {
            return <DefaultColumn {...info} />;
          } else if (item === "#") {
            return (
              <Text className="table-row-text index-text">
                {+info.row.id + 1}
              </Text>
            );
          } else {
            return info.getValue();
          }
        },
        footer: ({ table }) => {
          return null;
        }
      })
    );
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: async (rowIndex, columnId, value) => {
        const oldData = [...data]; // Clone current data to work with

        // Handle updates and potential new cycle creation
        if (oldData[rowIndex][columnId] !== value) {
          const submitValue = ["diesel", "toll", "expenses"].includes(columnId)
            ? parseFloat(value)
            : value;

          const updatedLoad = {
            ...oldData[rowIndex],
            [columnId]: submitValue
          };

          try {
            // Update existing load
            await updateLoadFieldsMutation.mutateAsync({
              loadId: updatedLoad.id,
              invoiced: updatedLoad.invoiced,
              paymentStatus: updatedLoad.paymentStatus,
              note: updatedLoad.note,
              broker: updatedLoad.broker
            });

            // Update the local state directly after the mutation
            oldData[rowIndex] = {
              ...oldData[rowIndex],
              [columnId]: submitValue
            };
          } catch (error) {
            console.error("Error updating load:", error);
            // Optionally handle error feedback here
            return; // Exit the function on error
          }
        }

        // Update the data once all processing is complete
        setData(oldData);
      }
    },
    debugTable: true
  });

  return (
    <ATable size="small" highlightOnHover={true}>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <View
                      {...{
                        className: `loads-sub-table-header-item ${
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }`,
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <Image
                            alt="arrow-up-icon"
                            src={ArrowUpIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        ),
                        desc: (
                          <Image
                            alt="arrow-down-icon"
                            src={ArrowDownIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        )
                      }[header.column.getNextSortingOrder() as string] ?? null}
                    </View>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody className="loads-sub-table-body">
        {table.getRowModel().rows.map((row, index) => {
          return (
            <TableRow
              key={row.id}
              className={row.original.transit ? "inTransit-row" : ""}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    key={cell.id}
                    className={`loads-sub-table-row-text ${
                      table.getRowModel().rows.length - 1 === index
                        ? "none-border"
                        : ""
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </ATable>
  );
};
