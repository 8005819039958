// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loads-sub-table-header-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #b3b3b3;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.loads-sub-table-body th,
.loads-sub-table-body td,
.accountant-loads-table-body th,
.accountant-loads-table-body td {
  border-bottom: 1px solid #212121;
}
.loads-sub-table-body .loads-sub-table-row-text,
.accountant-loads-table-body .loads-sub-table-row-text {
  color: #b7b7b7;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.loads-sub-table-body .loads-text-unpaid,
.accountant-loads-table-body .loads-text-unpaid {
  text-transform: lowercase;
  color: #white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.5px 5px;
}
.loads-sub-table-body .loads-text-cash,
.accountant-loads-table-body .loads-text-cash {
  text-transform: capitalize;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.loads-sub-table-body .none-border,
.accountant-loads-table-body .none-border {
  border-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Loads/SubTable/loads-sub-table-style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAIE;;;;EAEE,gCAAA;AACJ;AAEE;;EACE,cAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAEE;;EACE,yBAAA;EACA,aAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AAAJ;AAGE;;EACE,0BAAA;EACA,WAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGE;;EACE,2BAAA;AAAJ","sourcesContent":[".loads-sub-table-header-item {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n  color: #b3b3b3;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  white-space: nowrap;\n}\n\n.loads-sub-table-body,\n.accountant-loads-table-body {\n  th,\n  td {\n    border-bottom: 1px solid #212121;\n  }\n\n  .loads-sub-table-row-text {\n    color: #b7b7b7;\n    font-family: \"Montserrat\", sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    cursor: pointer;\n  }\n\n  .loads-text-unpaid {\n    text-transform: lowercase;\n    color: #white;\n    font-family: \"Montserrat\", sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n\n    width: fit-content;\n    padding: 1.5px 5px;\n  }\n\n  .loads-text-cash {\n    text-transform: capitalize;\n    color: #000;\n    font-family: \"Montserrat\", sans-serif;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n  }\n\n  .none-border {\n    border-bottom: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
