import {
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableFoot,
  Text,
  TableCell,
  Image
} from "@aws-amplify/ui-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { Fragment, useState } from "react";

import "./summary-table-style.scss";
import { capitalizeFirstLetter } from "../../../utils";
import ArrowDownIcon from "../../../assets/images/arrow-down-icon.svg";
import ArrowUpIcon from "../../../assets/images/arrow-up-icon.svg";

export const AccountantSummary = ({ data }) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper();

  const columns = [];

  [
    "#",
    "owner",
    "driverName",
    "gross",
    "paid",
    "unpaid",
    "cash",
    "missingInvoice"
  ].forEach((item) => {
    columns.push(
      columnHelper.accessor(item, {
        header: (info) => {
          const col = info.column.id;
          if (col === "driverName") {
            return "Driver";
          } else if (col === "missingInvoice") {
            return "Missing Invoice";
          } else {
            return capitalizeFirstLetter(info.column.id);
          }
        },
        cell: (info) => {
          if (["gross", "paid", "cash"].includes(item)) {
            let className = "basicTable-text";

            if (item === "gross") className = "grossAmount-text";
            else if (item === "paid") {
              className = "paidAmount-text";
              if (info.getValue() <= 0) {
                className = "cashAmountZero-text";
              }
            } else if (item === "cash") className = "cashAmount-text";

            return (
              <Text className={className}>
                {`$${info.getValue().toFixed(2)}`}
              </Text>
            );
          } else if (item === "missingInvoice") {
            if (info.getValue() > 0) {
              return (
                <Text className="missingInvoices-text">{info.getValue()}</Text>
              );
            } else {
              return (
                <Text className="missingInvoicesZero-text">
                  {info.getValue()}
                </Text>
              );
            }
          } else if (item === "owner") {
            return (
              <Text
                className={
                  info.row.original.own
                    ? "ownerInside-text"
                    : "ownerOutside-text"
                }
              >
                {info.getValue()}
              </Text>
            );
          } else if (item === "driverName") {
            return <Text className="driver-text">{info.getValue()}</Text>;
          } else if (item === "unpaid") {
            if (info.getValue() <= 0) {
              return <Text className="paidInFull-text">Paid in full</Text>;
            } else {
              return `$${info.getValue().toFixed(2)}`;
            }
          } else if (item === "#") {
            return (
              <Text className="table-row-text index-text">
                {+info.row.id + 1}
              </Text>
            );
          } else {
            return info.getValue();
          }
        },
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => sum + row.getValue(item), 0);
          if (["gross", "paid", "cash", "unpaid"].includes(item)) {
            return <Text>{`$${total.toFixed(2)}`}</Text>;
          } else if (item === "missingInvoice") {
            return <Text>{total}</Text>;
          } else {
            return "";
          }
        }
      })
    );
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    sortDescFirst: true,
    enableSorting: true,
    enableMultiSort: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  return (
    <View>
      <View className="stickyFooter-table-container">
        <ATable width="100%" variation="striped" className="stickyFooter-table">
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => {
                  return (
                    <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <View
                          {...{
                            className: `table-header-item ${
                              header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : ""
                            }`,
                            onClick: header.column.getToggleSortingHandler()
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <Image
                                alt="arrow-up-icon"
                                src={ArrowUpIcon}
                                width="16px"
                              />
                            ),
                            desc: (
                              <Image
                                alt="arrow-down-icon"
                                src={ArrowDownIcon}
                                width="16px"
                              />
                            )
                          }[header.column.getNextSortingOrder() as string] ??
                            null}
                        </View>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className="accountant-summary-table-body">
            {table.getRowModel().rows.map((row) => {
              return (
                <Fragment key={row.id}>
                  <TableRow
                    onClick={row.getToggleExpandedHandler()}
                    style={{ cursor: "pointer" }}
                  >
                    {/* first row is a normal row */}
                    {row.getVisibleCells().map((cell, index) => {
                      return (
                        <TableCell
                          key={cell.id}
                          className={`accountant-summary-${cell.column.id}`}
                        >
                          <Text className={"table-body-item"}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Text>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
          <TableFoot className="stickyFooter-tfoot">
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow>
                {footerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    <Text>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFoot>
        </ATable>
        {/* <View>{table.getRowModel().rows.length} Rows</View> */}
      </View>
    </View>
  );
};
