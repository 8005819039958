export enum Roles {
  SuperAdmin = "superAdmin",
  Owner = "owner",
  Accountant = "accountant",
  HeadDispatch = "headDispatch",
  Dispatch = "dispatch",
  Driver = "driver"
}

export type SidebarProps = {
  visibleSidebar: boolean;
  setVisibleSidebar: (value: boolean) => void;
};

export const defaultDateFormat = "LL/dd/yyyy";
