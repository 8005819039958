import { endOfDay, startOfDay, subDays } from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { DateTime } from "luxon";

import { defaultDateFormat } from "./constants";

export const last9CharsOfStr = (str) => {
  return str.slice(-9);
};

export const processLoadWithPayment15or30BD = (load) => {
  const payment = load?.payment;
  const gross = load?.price;
  if (["15", "30"].some((value) => payment?.includes(value)))
    return {
      ...load,
      gross: gross * 0.97,
      payment: payment.replace(/business days/gi, "BD")
    };
  else
    return {
      ...load,
      payment: payment.replace(/business days/gi, "BD")
    };
};

export const generateRandomNumber = () => {
  //dummy
  // Generate a random number between 0 and 1
  const random = Math.random();

  // 30% chance to return 0
  if (random < 0.3) {
    return 0;
  }

  // 60% chance to return a number between 1 and 10
  return Math.floor(Math.random() * 10) + 1;
};

// Function to convert MM/DD/YYYY to YYYY-MM-DD (ISO format)
function parseDate(dateString) {
  const [month, day, year] = dateString.split("/");
  return new Date(`${year}-${month}-${day}`);
}

// Function to convert date range to a comparable format (Date object)
function parseDateRange(dateRange) {
  const [startDate, endDate] = dateRange
    .split("-")
    .map((date) => parseDate(date));
  return { startDate, endDate };
}

export const timeZone = "America/New_York";

export const getWeeksInMonth = (month, year) => {
  const weeks = [];
  const startMonthDate = DateTime.fromObject(
    { year, month, day: 1 },
    { zone: timeZone }
  );
  const currentDate = DateTime.now().setZone(timeZone);
  const endOfMonth = startMonthDate.endOf("month");

  // Find the first Monday of or after the first of the month
  let date =
    startMonthDate.weekday === 1
      ? startMonthDate
      : startMonthDate.plus({ days: 8 - startMonthDate.weekday });

  // Collect weeks
  while (date <= endOfMonth && date <= currentDate) {
    const startOfWeek = date;
    const endOfWeek = startOfWeek.plus({ days: 6 }).endOf("day");

    // Include weeks that start within the month
    if (startOfWeek.month === month) {
      weeks.push({
        start: startOfWeek.toUTC().toISO(),
        end: endOfWeek.toUTC().toISO()
      });
    }

    date = date.plus({ weeks: 1 });
  }

  return weeks;
};

export const getMonthsAndYears = () => {
  const startDate = DateTime.fromObject(
    { year: 2024, month: 6 },
    { zone: timeZone }
  );
  const currentDate = DateTime.now().setZone(timeZone);

  const monthsAndYears = [];
  let date = startDate;

  while (date <= currentDate) {
    monthsAndYears.push({ month: date.month, year: date.year });
    date = date.plus({ months: 1 });
  }

  return monthsAndYears;
};

export const getCycleFromDate = (date) => {
  date = utcToZonedTime(date, "America/New_York");
  const prevMonday = new Date(date);
  prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
  const nextMonday = new Date(date);
  nextMonday.setDate(
    nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
  );

  return {
    start: zonedTimeToUtc(startOfDay(prevMonday), "America/New_York"),
    end: zonedTimeToUtc(endOfDay(subDays(nextMonday, 1)), "America/New_York")
  };
};

export const getCurrentCycle = () => {
  return getCycleFromDate(new Date().toISOString());
};

export const prepareDispatchTableData = (loads) => {
  const data = [];
  const temp = [];
  for (let load of loads) {
    load = processLoadWithPayment15or30BD(load);
    let driver = load.driver;
    if (!driver) driver = { _id: "unassigned", name: "Unassigned" };
    const row = temp.findIndex((row) => row.driverId === driver._id);
    const loadPickup = last9CharsOfStr(load.pickup);
    const loadDropoff = last9CharsOfStr(load.dropoff);
    const loadLoad = load.load;
    const preparedLoad = {
      ...load,
      pickup: loadPickup,
      dropoff: loadDropoff,
      load: loadLoad,
      loadModel: load
    };

    if (row > -1) {
      temp[row].loads.push(preparedLoad);
    } else {
      temp.push({
        dispatch: load.dispatch.name,
        driverId: driver._id,
        driverName: driver.name,
        gross: 0,
        destination: "",
        loads: [preparedLoad]
      });
    }
  }

  for (const row of temp) {
    const lastLoad = row.loads[row.loads.length - 1];
    const gross = row.loads.reduce(
      (accumulator, currentValue) => accumulator + currentValue.price,
      0
    );
    data.push({
      ...row,
      gross,
      destination: `${lastLoad.pickup} $sep ${lastLoad.dropoff}`
    });
  }
  return data;
};

export const prepareAccountantSummaryTableData = (loads) => {
  const data = [];
  const temp = [];
  for (let load of loads) {
    load = processLoadWithPayment15or30BD(load);
    let driver = load.driver;
    const owner = load.owner;
    if (!driver) driver = { _id: "unassigned", name: "Unassigned" };
    const row = temp.findIndex((row) => row.driverId === driver._id);
    const loadPickup = last9CharsOfStr(load.pickup);
    const loadDropoff = last9CharsOfStr(load.dropoff);
    const loadLoad = load.load;
    const preparedLoad = {
      ...load,
      pickup: loadPickup,
      dropoff: loadDropoff,
      load: loadLoad,
      loadModel: load
    };

    if (row > -1) {
      temp[row].loads.push(preparedLoad);
    } else {
      temp.push({
        owner: owner.name,
        own: owner.own,
        driverId: driver._id,
        driverName: driver.name,
        gross: 0,
        destination: "",
        loads: [preparedLoad]
      });
    }
  }

  for (const row of temp) {
    const paid = row.loads
      .filter(
        (load) =>
          load.paymentStatus !== "UNPAID" || !load?.paymentStatus?.length > 0
      )

      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0
      );
    const cash = row.loads
      .filter((load) => load.paymentStatus === "CASH")
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0
      );
    const missingInvoice = row.loads.filter(
      (load) => load.invoiced == null
    ).length;

    const gross = row.loads.reduce(
      (accumulator, currentValue) => accumulator + currentValue.price,
      0
    );
    data.push({
      ...row,
      gross,
      paid,
      unpaid: gross - paid,
      cash,
      missingInvoice
    });
  }
  return data;
};

export const prepareAccountantPayoutTableData = (
  loads,
  cycles,
  salaries,
  fees
) => {
  let data = [];
  const temp = [];

  for (let load of loads) {
    load = processLoadWithPayment15or30BD(load);
    const cycleDates = getCycleFromDate(load.date);
    const formattedCycle = formatCycleRange(cycleDates.start, cycleDates.end);
    let driver = load.driver;
    const owner = load.owner;
    const cycle = driver
      ? cycles.find(
          (c) => c.cycle === formattedCycle && c.driver._id === driver._id
        )
      : undefined;

    if (!driver) driver = { _id: "unassigned", name: "Unassigned" };
    const row = temp.findIndex((row) => row.cycle === formattedCycle);

    const gross = load.price;
    const preparedLoad = {
      ...load,
      owner: owner.name,
      own: owner.own,
      ownerId: owner._id,
      driverId: driver._id,
      driverName: driver.name,
      alias: driver.alias,
      gross,
      diesel: cycle?.diesel || 0,
      toll: cycle?.toll || 0,
      subTotal: 0,
      driverSalary: "",
      total: 0,
      expenses: cycle?.expenses || 0,
      fee: fees[1] && fees[1] !== 0 ? fees[1] : fees[0], // debt TODO
      netGross: 0,
      maintenance: cycle?.maintenance,
      cycleModel: cycle,
      cycle: formattedCycle
    };

    if (row > -1) {
      temp[row].loads.push(preparedLoad);
    } else {
      temp.push({
        cycle: formattedCycle,
        on: 0,
        off: generateRandomNumber(),
        cyclePaymentStatus: "Paid",
        loads: [preparedLoad]
      });
    }
  }

  for (const row of temp) {
    const sub = {}; // Initialize sub for each temp entry

    for (const load of row.loads) {
      const { driverId } = load;

      if (!sub[driverId]) {
        sub[driverId] = {
          ...load, // Copy all properties from load
          cyclePaymentStatus: undefined,
          gross: 0, // Initialize gross to 0 to accumulate the sum
          cash: 0 // Initialize cash to 0 to accumulate the sum for loads with paymentStatus 'CASH'
        };
      }

      // Accumulate the gross value for each driver within this cycle's loads
      sub[driverId].gross += load.price;

      // Accumulate the cash value only for loads with paymentStatus 'CASH'
      if (load.paymentStatus === "CASH") {
        sub[driverId].cash += load.price;
      }

      sub[driverId].salaryPercent = calcPercent(salaries, sub[driverId].gross);
      sub[driverId].cycleModel = load.cycleModel; // temp solution
      sub[driverId].cycle = load.cycle; // temp solution
      //if (!load.invoiced)

      sub[driverId].cyclePaymentStatus =
        load?.cycleModel?.paymentStatus ?? false; //= false;
    }

    const paid = row.loads
      .filter((load) => load.invoiced !== null)
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.price,
        0
      );

    const pay = row.loads.reduce(
      (accumulator, currentValue) => accumulator + currentValue.price,
      0
    ); // gross

    const tmp = { ...row };
    console.log(Object.values(sub), "tagsdg");
    data.push({
      ...tmp,
      on: Object.keys(sub).length,
      paid,
      unpaid: pay - paid,
      pay,
      sub: Object.values(sub) // Include the 'sub' property here with accumulated gross and cash per driver
    });
  }

  const size = data.length;
  if (size > 4) {
    // Sort by the start date of the range
    data = data.sort((a, b) => {
      const dateA = parseDateRange(a.cycle);
      const dateB = parseDateRange(b.cycle);
      return dateA.startDate - dateB.startDate;
    });

    data[size - 1].cyclePaymentStatus = "Upcoming";
    data[size - 2].cyclePaymentStatus = "Upcoming";
    data[size - 3].cyclePaymentStatus = "Friday pay day";
  }
  return data;
};

export const prepareAccountantLoadsTableData = (loads) => {
  const data = [];
  for (let load of loads) {
    load = processLoadWithPayment15or30BD(load);
    let driver = load.driver;
    const owner = load.owner;
    if (!driver) driver = { _id: "unassigned", name: "Unassigned" };
    const loadPickup = last9CharsOfStr(load.pickup);
    const loadDropoff = last9CharsOfStr(load.dropoff);
    const loadLoad = load.load;
    const preparedLoad = {
      ...load,
      owner: owner.name,
      own: owner.own,
      driverName: driver.name,
      pickup: loadPickup,
      dropoff: loadDropoff,
      load: loadLoad,
      loadModel: load
    };

    data.push(preparedLoad);
  }

  return data;
};

export const calcPercent = (salaries, sum) => {
  for (const salary of salaries) {
    if (sum <= salary.max && sum >= salary.min) return salary.percent;
  }
};

export const prepareOwnerTableData = (loads, cycles, salaries, fees) => {
  //if (!salaries.length > 0) return [];
  const data = [];
  const temp = [];

  for (let load of loads) {
    load = processLoadWithPayment15or30BD(load);
    const cycleDates = getCycleFromDate(load.date);
    const formattedCycle = formatCycleRange(cycleDates.start, cycleDates.end);
    let driver = load.driver;
    const owner = load.owner;
    //temp
    const cycle = driver
      ? cycles.find(
          (c) => c.cycle === formattedCycle && c.driver._id === driver._id
        )
      : undefined;

    if (!driver) driver = { _id: "unassigned", name: "Unassigned" };
    const row = temp.findIndex((row) => row.driverId === driver._id);
    const loadPickup = last9CharsOfStr(load.pickup);
    const loadDropoff = last9CharsOfStr(load.dropoff);
    const loadLoad = load.load;
    const preparedLoad = {
      ...load,
      ownerId: owner._id,
      pickup: loadPickup,
      dropoff: loadDropoff,
      load: loadLoad
    };
    if (row > -1) {
      temp[row].loads.push(preparedLoad);
    } else {
      temp.push({
        ownerId: owner._id,
        driverId: driver._id,
        alias: driver.alias,
        driverName: driver.name,
        gross: 0,
        diesel: cycle?.diesel || 0,
        toll: cycle?.toll || 0,
        subTotal: 0,
        driverSalary: "",
        total: 0,
        expenses: cycle?.expenses || 0,
        fee: fees[1] ? fees[1] : fees[0],
        netGross: 0,
        maintenance: cycle?.maintenance,
        driverModel: driver,
        cycleModel: cycle,
        formattedCycle,
        loads: [preparedLoad]
      });
    }
  }

  for (const row of temp) {
    const gross = row.loads.reduce(
      (accumulator, currentValue) => accumulator + currentValue.price,
      0
    );
    const tmp = { ...row };

    data.push({
      ...tmp,
      gross,
      salaryPercent: calcPercent(salaries, gross)
    });
  }

  return data;
};

// export const prepareAccountantPayoutSubTableData = (loads, cycles, salaries, fees) => {
//   //if (!salaries.length > 0) return [];
//   //todo add cycle id for editing functionality
//   console.log(0);
//   const data = [];
//   const temp = [];
//   console.log(1);
//   for (const load of loads) {
//     const cycleDates = getCycleFromDate(load.date);
//     const formattedCycle = formatCycleRange(cycleDates.start, cycleDates.end);
//     let driver = load.driver;
//     const cycle = cycles.find(
//       (c) => c.cycle === formattedCycle && c.driver._id === driver._id
//     );

//     if (!driver) driver = { _id: "unassigned", name: "Unassigned" };
//     const row = temp.findIndex((row) => row.driverId === driver._id);
//     const loadPickup = load.pickup;
//     const loadDropoff = load.dropoff;
//     const loadLoad = load.load;
//     const preparedLoad = {
//       ...load,
//       pickup: loadPickup,
//       dropoff: loadDropoff,
//       load: loadLoad,
//     };
//     if (row > -1) {
//       temp[row].loads.push(preparedLoad);
//     } else {
//       temp.push({
//         driverId: driver._id,
//         alias: driver.alias,
//         driverName: driver.name,
//         gross: 0,
//         diesel: cycle?.diesel || 0,
//         toll: cycle?.toll || 0,
//         subTotal: 0,
//         driverSalary: "",
//         total: 0,
//         expenses: cycle?.expenses || 0,
//         fee: fees[1] ? fees[1] : fees[0],
//         netGross: 0,
//         maintenance: cycle?.maintenance,
//         driverModel: driver,
//         cycleModel: cycle,
//         formattedCycle,
//         loads: [preparedLoad],
//       });
//     }
//   }

//   for (const row of temp) {
//     const gross = row.loads.reduce(
//       (accumulator, currentValue) => accumulator + currentValue.price,
//       0
//     );
//     const tmp = { ...row };

//     data.push({
//       ...tmp,
//       gross,
//       salaryPercent: calcPercent(salaries, gross),
//     });
//   }

//   return data;
// };

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const cleanFromModelDefaults = (model) => {
  const newModel = { ...model };
  delete newModel._version;
  delete newModel._lastChangedAt;
  delete newModel._deleted;
  delete newModel.updatedAt;
  delete newModel.createdAt;
  return newModel;
};

export const formatCycleRange = (start, end) => {
  return `${formatInTimeZone(
    start,
    "America/New_York",
    defaultDateFormat
  )}-${formatInTimeZone(end, "America/New_York", defaultDateFormat)}`;
};

export const convertLoadsDataToCycles = (data) => {
  const cycles = [];
  const usedStartDates = [];
  for (const load of data) {
    const cycle = getCycleFromDate(load.date);
    if (!usedStartDates.includes(cycle.start.toISOString())) {
      usedStartDates.push(cycle.start.toISOString());
      cycles.push(cycle);
    }
  }
  return cycles;
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

export const filterLoads = (loads, filters) => {
  return loads.filter((load) => {
    if (
      filters.driverFilter &&
      !load.driverName
        .toLowerCase()
        .includes(filters.driverFilter.toLowerCase())
    ) {
      return false;
    }
    if (
      filters.dispatchFilter &&
      !load.dispatch
        .toLowerCase()
        .includes(filters.dispatchFilter.toLowerCase())
    ) {
      return false;
    }
    if (filters.selectedFilter === "owners" && load.own) {
      return false;
    }
    if (filters.selectedFilter === "companyDrivers" && !load.own) {
      return false;
    }
    if (filters.unpaidFilter && load.paymentStatus !== "UNPAID") {
      return false;
    }
    if (filters.missingInvoiceFilter && !!load.invoiced) {
      return false;
    }
    return true;
  });
};
