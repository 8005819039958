import { useQuery } from "react-query";

import apiClient from "../api/axios";

const fetchSalaries = async (startDate: string, endDate: string) => {
  if (!startDate || !endDate) return [];
  const { data } = await apiClient.get("/salaries");
  return data;
};

export const useSalaries = (startDate: string, endDate: string) => {
  return useQuery(
    ["salaries", startDate, endDate],
    () => fetchSalaries(startDate, endDate),
    {
      enabled: !!startDate && !!endDate
    }
  );
};
