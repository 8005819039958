// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
p {
  color: white !important;
}

.container {
  display: flex;
  background-color: #1b1b1b;
}
.container .section-container {
  width: calc(100% - 66px) !important;
  margin: 0 !important;
}
.container .section-container-small {
  width: calc(100% - 175px) !important;
}
.container .main-container {
  overflow: auto !important;
  height: calc(100vh - 67.4px);
}
.container .main-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.container .main-container::-webkit-scrollbar-thumb {
  background: #9aa6ac;
  border-radius: 10px;
}
.container .amplify-button:focus, .container .amplify-button:hover {
  background-color: transparent;
  box-shadow: none;
}
.container .amplify-button svg path {
  fill: #828282;
}
.container .error-text {
  color: #d32f2f;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA;;EAEE,uBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;AACE;EACE,mCAAA;EACA,oBAAA;AACJ;AAEE;EACE,oCAAA;AAAJ;AAGE;EACE,yBAAA;EACA,4BAAA;AADJ;AAGI;EACE,UAAA;EACA,WAAA;AADN;AAII;EACE,mBAAA;EACA,mBAAA;AAFN;AAOI;EAEE,6BAAA;EACA,gBAAA;AANN;AASI;EACE,aAAA;AAPN;AAWE;EACE,cAAA;EACA,eAAA;AATJ;;AAaA;EACE,eAAA;AAVF","sourcesContent":["body,\np {\n  color: white !important;\n}\n\n.container {\n  display: flex;\n  background-color: #1b1b1b;\n\n  .section-container {\n    width: calc(100% - 66px) !important;\n    margin: 0 !important;\n  }\n\n  .section-container-small {\n    width: calc(100% - 175px) !important;\n  }\n\n  .main-container {\n    overflow: auto !important;\n    height: calc(100vh - 67.4px);\n\n    &::-webkit-scrollbar {\n      width: 6px;\n      height: 6px;\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background: #9aa6ac;\n      border-radius: 10px;\n    }\n  }\n\n  .amplify-button {\n    &:focus,\n    &:hover {\n      background-color: transparent;\n      box-shadow: none;\n    }\n\n    svg path {\n      fill: #828282;\n    }\n  }\n\n  .error-text {\n    color: #d32f2f;\n    font-size: 14px;\n  }\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
