import {
  Text,
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  SelectField,
  Image
} from "@aws-amplify/ui-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState
} from "@tanstack/react-table";
import { format, parse } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";

import { defaultDateFormat } from "../../../constants";
import { capitalizeFirstLetter } from "../../../utils";
import ArrowUpIcon from "../../../assets/images/arrow-up-icon.svg";
import ArrowDownIcon from "../../../assets/images/arrow-down-icon.svg";
import "./loads-table-style.scss";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useUpdateLoadFields } from "../../../services/loadService";

export const AccountantLoads = ({ data, setData }) => {
  const updateLoadFieldsMutation = useUpdateLoadFields();
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper();
  // Give our default column cell renderer editing superpowers!
  const DefaultColumn = ({
    getValue,
    row: { index },
    column: { id },
    table
  }) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);
    let isEditing = false;
    if (["note", "broker"].includes(id))
      isEditing = initialValue ? false : true;
    const [editing, setEditing] = useState(isEditing);
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(index, id, value);
      setEditing(false);
    };
    const fieldRef = useRef(null);
    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    useEffect(() => {
      if (editing && value && value.length > 0) fieldRef?.current?.focus();
    }, [editing, value]);
    let Element;
    if (editing) {
      if (id === "paymentStatus") {
        Element = (
          <SelectField
            minWidth={100}
            style={{
              fontSize: 12,
              padding: 3
            }}
            label="Payment status"
            labelHidden={true}
            ref={fieldRef}
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          >
            <option value="UNPAID">UNPAID</option>
            <option value="PAID">PAID</option>
            <option value="FACTORING">FACTORING</option>
            <option value="CASH">Cash</option>
            <option value="CHECK">Check</option>
            <option value="MONEY_ORDER">Money Order</option>
            <option value="COMCHEK">Comchek</option>
            <option value="CREDIT_CARD">Credit Card</option>
            <option value="DIRECT_DEPOSIT">Direct Deposit</option>
            <option value="VENMO">Venmo</option>
            <option value="CASHAPP">CashApp</option>
            <option value="USHIP">UShip</option>
            <option value="ZELLE">Zelle</option>
            <option value="SUPERPAY">SuperPay</option>
            <option value="OTHER">Other</option>
          </SelectField>
        );
      } else if (id === "invoiced") {
        let val = value;
        if (value && typeof value !== "object")
          val = parse(value, "yyyy-mm-dd", new Date());
        Element = (
          <DatePicker
            selected={val}
            onChange={(date) => setValue(date)}
            onBlur={onBlur}
          />
        );
      } else {
        Element = (
          <input
            style={{
              maxWidth: 100
            }}
            ref={fieldRef}
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          />
        );
      }
    } else {
      if (id === "paymentStatus" && value?.toLowerCase() === "unpaid") {
        Element = (
          <Text
            className="loads-sub-table-row-text "
            onDoubleClick={() => setEditing(!editing)}
          >
            {value}
          </Text>
        );
      } else if (id === "paymentStatus" && value?.toLowerCase() === "cash") {
        Element = (
          <Text
            className="loads-sub-table-row-text paymentStatusCash-text"
            onDoubleClick={() => setEditing(!editing)}
          >
            {value}
          </Text>
        );
      } else if (id === "invoiced" && value === null) {
        Element = (
          <Text
            className="loads-sub-table-row-text"
            onDoubleClick={() => setEditing(!editing)}
          >
            Missing
          </Text>
        );
      } else if (
        id === "invoiced" &&
        value !== null &&
        typeof value !== "object"
      ) {
        Element = (
          <Text
            className="loads-sub-table-row-text"
            onDoubleClick={() => setEditing(!editing)}
          >
            {format(new Date(value), defaultDateFormat)}
          </Text>
        );
      } else if (id === "invoiced" && typeof value === "object") {
        // do not delete. required for second while value is object to prevent error
      } else {
        Element = (
          <Text
            className="loads-sub-table-row-text"
            onDoubleClick={() => setEditing(!editing)}
          >
            {value}
          </Text>
        );
      }
    }
    return <Fragment>{Element}</Fragment>;
  };

  const columns = [];

  [
    "#",
    "owner",
    "driverName",
    "load",
    "pickup",
    "price",
    "payment",
    "paymentStatus",
    "invoiceNumber",
    "broker",
    "invoiced",
    "note"
  ].forEach((item) => {
    columns.push(
      columnHelper.accessor(item, {
        header: (info) => {
          if (item === "driverName") {
            return "Driver";
          } else if (item === "pickup") {
            return "Pick up";
          } else if (item === "dropoff") {
            return "Drop off";
          } else if (item === "invoiceNumber") {
            return "Load ID";
          } else if (item === "payment") {
            return "Method";
          } else if (item === "paymentStatus") {
            return "Payment Status";
          } else {
            return capitalizeFirstLetter(info.column.id);
          }
        },
        cell: (info) => {
          //console.log(`TEstttt: ${item}`, info.getValue());
          if (item === "date") {
            return format(new Date(info.getValue()), defaultDateFormat);
          } else if (item === "price") {
            return `$${info.getValue().toFixed(2)}`;
          } else if (item === "owner") {
            return (
              <Text
                className={
                  info.row.original.own
                    ? "ownerInside-text"
                    : "ownerOutside-text"
                }
              >
                {info.getValue()}
              </Text>
            );
          } else if (item === "driverName") {
            return <Text className="driver-text">{info.getValue()}</Text>;
          } else if (
            ["paymentStatus", "broker", "invoiced", "note"].includes(item)
          ) {
            return <DefaultColumn {...info} />;
          } else if (item === "#") {
            return (
              <Text className="table-row-text index-text">
                {+info.row.id + 1}
              </Text>
            );
          } else {
            return <Text className="basicTable-text">{info.getValue()}</Text>;
          }
        },
        footer: ({ table }) => {
          return null;
        }
      })
    );
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex && old[rowIndex][columnId] !== value) {
              const updated = { ...old[rowIndex] };

              if (
                columnId === "invoiced" &&
                typeof value === "object" &&
                value !== null
              ) {
                updated[columnId] = `${value.getFullYear()}-${
                  (value.getMonth() + 1).toString().length === 1 ? "0" : ""
                }${value.getMonth() + 1}-${
                  value.getDate().toString().length === 1 ? "0" : ""
                }${value.getDate()}`;
              } else {
                updated[columnId] = value;
              }

              // Prepare data to update
              const loadId = updated._id; // Ensure you have access to the load ID
              const invoiced = updated.invoiced;
              const paymentStatus = updated.paymentStatus;
              const note = updated.note;
              const broker = updated.broker;

              // Call the mutation
              updateLoadFieldsMutation.mutate({
                loadId,
                invoiced,
                paymentStatus,
                note,
                broker
              });

              return {
                ...old[rowIndex],
                ...updated
              };
            }
            return row;
          })
        );
      }
    },
    debugTable: true
  });

  return (
    <ATable highlightOnHover={true} variation="striped">
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <View
                      {...{
                        className: `table-header-item ${
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }`,
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <Image
                            alt="arrow-up-icon"
                            src={ArrowUpIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        ),
                        desc: (
                          <Image
                            alt="arrow-down-icon"
                            src={ArrowDownIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        )
                      }[header.column.getNextSortingOrder() as string] ?? null}
                    </View>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody className="accountant-loads-table-body">
        {table.getRowModel().rows.map((row, index) => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    key={cell.id}
                    className={`accountant-loads-${cell.column.id} ${
                      cell.getValue() ? "hasData" : "hasNoData"
                    } loads-sub-table-row-text ${
                      table.getRowModel().rows.length - 1 === index
                        ? "none-border"
                        : ""
                    } ${
                      cell.column.id === "paymentStatus" &&
                      cell.getValue() === "UNPAID"
                        ? "unpaid-td"
                        : ""
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </ATable>
  );
};
