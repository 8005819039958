import { useForm } from "react-hook-form";
import { Input, PasswordField, Button, View } from "@aws-amplify/ui-react";
import apiClient from "../../../api/axios";
import "./dispatch-style.scss";

export const AddDispatchScreen = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    const post = { ...data, username: data.email };
    apiClient
      .post("/dispatch/processRegisterDispatch", post)
      .then((res) => {
        if (res.data.success) {
          alert("success");
        } else {
          throw new Error("Fail");
        }
      })
      .catch((e) => {
        alert("error!");
        console.log(e);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <View className="add-dispatch-container">
        <View className="form-block">
          <View>
            <Input
              placeholder="Name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className="error-text">This field is required</span>
            )}
          </View>
          <View>
            <Input
              type="email"
              placeholder="Email"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className="error-text">This field is required</span>
            )}
          </View>
          <View>
            <PasswordField
              autoComplete="new-password"
              label="Password"
              labelHidden={true}
              placeholder="Password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <span className="error-text">This field is required</span>
            )}
          </View>
          <Button
            className="submit-button"
            variation="primary"
            loadingText="Submitting"
            type="submit"
          >
            Submit
          </Button>
        </View>
      </View>
    </form>
  );
};
