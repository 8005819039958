import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Text,
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  SelectField,
  Flex,
  TableFoot
} from "@aws-amplify/ui-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState
} from "@tanstack/react-table";

import { capitalizeFirstLetter } from "../../../utils";
import {
  useUpdateCycleFields,
  useCreateCycle
} from "../../../services/cycleService";

export const AccountantPayoutSub = ({ data: pData, setData: setPData }) => {
  const [data, setData] = useState(pData?.sub); // Why it's done this way? Not directly parentRow to useTable data?
  useEffect(() => {
    setData(pData?.sub);
  }, [pData]);

  const updateCycleFieldsMutation = useUpdateCycleFields();
  const createCycleMutation = useCreateCycle();
  const columnHelper = createColumnHelper();

  const DefaultColumn = ({
    getValue,
    row: { index },
    column: { id },
    table,
    textClass = "",
    number
  }) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    const [editing, setEditing] = useState(false);

    const onBlur = () => {
      let submitValue = value;
      if (id === "paymentStatus") {
        submitValue = value === "PAID";
      }
      table.options.meta?.updateData(index, id, submitValue);
      setEditing(false);
    };

    const fieldRef = useRef(null);

    useEffect(() => {
      if (number && initialValue) setValue(initialValue.toFixed(2));
      else setValue(initialValue);
    }, [initialValue, number]);

    useEffect(() => {
      if (editing && value && value.length > 0) fieldRef?.current?.focus();
    }, [editing, value]);

    let Element;
    if (editing) {
      if (id === "cyclePaymentStatus") {
        Element = (
          <SelectField
            minWidth={100}
            style={{
              fontSize: 12,
              padding: 3
            }}
            label="Payment status"
            labelHidden={true}
            ref={fieldRef}
            value={value ? "PAID" : "UNPAID"}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
          >
            <option value="UNPAID">UNPAID</option>
            <option value="PAID">PAID</option>
          </SelectField>
        );
      } else {
        Element = (
          <Flex direction="row">
            {number && <Text>$</Text>}
            <input
              ref={fieldRef}
              value={value as string}
              onChange={(e) => setValue(e.target.value)}
              onBlur={onBlur}
              style={{ width: 60 }}
            />
          </Flex>
        );
      }
    } else {
      if (id === "cyclePaymentStatus") {
        Element = (
          <Text
            onDoubleClick={() => setEditing(true)}
            className={value === true ? "cyclePaid-text" : "cycleUnpaid-text"}
          >
            {value === true ? "PAID" : "UNPAID"}
          </Text>
        );
      } else {
        Element = (
          <Text
            className={`table-row-text ${textClass}`}
            onDoubleClick={() => setEditing(true)}
          >
            {number && "-$"}
            {value}
          </Text>
        );
      }
    }

    return <Fragment>{Element}</Fragment>;
  };

  const columns = [
    "#",
    "owner",
    "driverName",
    "gross",
    "diesel",
    "cash",
    "toll",
    "subTotal",
    "driverSalary",
    "fee",
    "total",
    "expenses",
    "netGross",
    "cyclePaymentStatus"
  ].map((item) =>
    columnHelper.accessor(item, {
      header: (info) => {
        const col = info.column.id;
        if (col === "driverName") {
          return "Driver";
        } else if (col === "gross") {
          return "Gross";
        } else if (col === "subTotal") {
          return "Sub Total";
        } else if (col === "driverSalary") {
          return "Driver Salary";
        } else if (col === "netGross") {
          return "Net Gross";
        } else if (col === "cyclePaymentStatus") {
          return "Payment Status";
        } else {
          return capitalizeFirstLetter(info.column.id);
        }
      },
      cell: (info) => {
        if (
          [
            "gross",
            "subTotal",
            "total",
            "driverSalary",
            "fee",
            "netGross",
            "cash"
          ].includes(item)
        ) {
          let val = info.getValue();
          const driverSalary =
            ((info.row.original.gross -
              (info.row.original.diesel + info.row.original.toll)) /
              100) *
            info.row.original.salaryPercent;
          const subTotal =
            info.row.original.gross -
            (info.row.original.diesel + info.row.original.toll);
          const total = subTotal - driverSalary;
          const fee = (info.row.original.gross / 100) * info.row.original.fee;
          if (item === "driverSalary") {
            val = driverSalary;
            return (
              <Text className="basicTable-text">
                <Text className="basicTable-text">
                  {info.row.original.salaryPercent}% -
                </Text>
                <Text className="salaryAmount-text">
                  &nbsp;${val.toFixed(2)}
                </Text>
              </Text>
            );
          }
          if (item === "fee") {
            val = fee;
            return (
              <Text className="basicTable-text">
                <Text className="basicTable-text">
                  {info.row.original.fee}% -
                </Text>
                <Text className="feeAmount-text">&nbsp;${val.toFixed(2)}</Text>
              </Text>
            );
          }
          if (item === "subTotal") val = subTotal;
          if (item === "total") val = total;
          if (item === "netGross")
            val = total - (info.row.original.expenses + fee);
          if (item === "total") {
            return <Text className="total-text">${val.toFixed(2)}</Text>;
          } else if (item === "gross") {
            return <Text className="grossAmount-text">${val.toFixed(2)}</Text>;
          } else if (item === "netGross") {
            return <Text className="netGross-text">${val.toFixed(2)}</Text>;
          }
          return <Text className="basicTable-text">${val.toFixed(2)}</Text>;
        } else if (["diesel"].includes(item)) {
          return <DefaultColumn textClass="diesel-text" {...info} number />;
        } else if (["toll"].includes(item)) {
          return <DefaultColumn textClass="toll-text" {...info} number />;
        } else if (["expenses"].includes(item)) {
          return <DefaultColumn textClass="expenses-text" {...info} number />;
        } else if (item === "owner") {
          return (
            <Text
              className={
                info.row.original.own ? "ownerInside-text" : "ownerOutside-text"
              }
            >
              {info.getValue()}
            </Text>
          );
        } else if (item === "driverName") {
          return <Text className="driver-text">{info.getValue()}</Text>;
        } else if (item === "cyclePaymentStatus") {
          return (
            <DefaultColumn textClass="cyclePaymentStatus-text" {...info} />
          );
        } else if (item === "#") {
          return (
            <Text className="table-row-text index-text">
              {+info.row.id + 1}
            </Text>
          );
        } else {
          return <Text className="basicTable-text">{info.getValue()}</Text>;
        }
      },
      footer: ({ table }) => {
        const total = table.getFilteredRowModel().rows.reduce((sum, row) => {
          const itemValue = row.getValue(item);
          let calculatedValue = itemValue;

          if (item === "driverSalary") {
            calculatedValue =
              ((row.original.gross -
                (row.original.diesel + row.original.toll)) /
                100) *
              row.original.salaryPercent;
          } else if (item === "subTotal") {
            calculatedValue =
              row.original.gross - (row.original.diesel + row.original.toll);
          } else if (item === "total") {
            const subTotal =
              row.original.gross - (row.original.diesel + row.original.toll);
            const driverSalary =
              ((row.original.gross -
                (row.original.diesel + row.original.toll)) /
                100) *
              row.original.salaryPercent;
            calculatedValue = subTotal - driverSalary;
          } else if (item === "fee") {
            calculatedValue = (row.original.gross / 100) * row.original.fee;
          } else if (item === "netGross") {
            const subTotal =
              row.original.gross - (row.original.diesel + row.original.toll);
            const driverSalary =
              ((row.original.gross -
                (row.original.diesel + row.original.toll)) /
                100) *
              row.original.salaryPercent;
            const total = subTotal - driverSalary;
            const fee = (row.original.gross / 100) * row.original.fee;
            calculatedValue = total - (row.original.expenses + fee);
          }

          return sum + calculatedValue;
        }, 0);

        if (
          [
            "gross",
            "diesel",
            "cash",
            "toll",
            "total",
            "subTotal",
            "driverSalary",
            "fee",
            "expenses",
            "netGross"
          ].includes(item)
        ) {
          return <Text>{`$${total.toFixed(2)}`}</Text>;
        } else {
          return "";
        }
      }
    })
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    sortDescFirst: true,
    enableSorting: true,
    enableMultiSort: true,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    meta: {
      updateData: async (rowIndex, columnId, value) => {
        const oldData = [...data];
        if (oldData[rowIndex][columnId] !== value) {
          const submitValue = ["diesel", "toll", "expenses"].includes(columnId)
            ? parseFloat(value)
            : value;

          const updatedCycle = {
            ...oldData[rowIndex].cycleModel,
            [columnId]: submitValue
          };
          if (oldData[rowIndex].cycleModel) {
            await updateCycleFieldsMutation.mutateAsync({
              cycle: updatedCycle.cycle,
              cycleId: updatedCycle._id,
              toll: updatedCycle.toll,
              diesel: updatedCycle.diesel,
              expenses: updatedCycle.expenses,
              paymentStatus: !!(updatedCycle?.cyclePaymentStatus === "PAID")
            });
            oldData[rowIndex] = {
              ...oldData[rowIndex],
              cycleModel: updatedCycle,
              [columnId]: submitValue
            };
          } else {
            const newCycle = {
              cycle: oldData[rowIndex].cycle,
              driver: oldData[rowIndex].driverId,
              owner: oldData[rowIndex].ownerId,
              toll: 0,
              diesel: 0,
              expenses: 0,
              paymentStatus: false,
              [columnId]: submitValue
            };
            try {
              const createdCycle =
                await createCycleMutation.mutateAsync(newCycle);
              oldData[rowIndex] = {
                ...oldData[rowIndex],
                cycleModel: {
                  ...newCycle,
                  _id: createdCycle._id,
                  cyclePaymentStatus: !!newCycle.paymentStatus
                },
                [columnId]: submitValue
              };
            } catch (error) {
              console.error("Error creating cycle:", error);
              return;
            }
          }
        }
        setPData(oldData);
      }
    },
    debugTable: true
  });

  return (
    <View>
      <View className={/*stickyFooter-table-container */ ""}>
        <ATable width="100%" className="stickyFooter-table">
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <View
                        className={`table-header-item ${
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }`}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </View>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <TableRow>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
          <TableFoot className="stickyFooter-tfoot">
            {table.getFooterGroups().map((footerGroup) => (
              <TableRow>
                {footerGroup.headers.map((header) => (
                  <TableCell key={header.id}>
                    <Text>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </Text>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableFoot>
        </ATable>
      </View>
    </View>
  );
};
