import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Input,
  PasswordField,
  Button,
  View,
  Heading
} from "@aws-amplify/ui-react";

import apiClient from "../../../api/axios";
import "../../headDispatch/AddDispatch/dispatch-style.scss";
import { useUser } from "../../../context/userContext";

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string; password: string }>();
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleLogin = async (data: { email: string; password: string }) => {
    const { email, password } = data;
    try {
      const response = await apiClient.post("/auth/login", { email, password });
      const { token, user, is2FAEnabled } = response.data;
      localStorage.setItem("token", token);
      setUser({
        id: user._id,
        roles: user.roles,
        exp: token.exp,
        iat: token.iat
        // is2FAEnabled:is2FAEnabled
      }); // Update user state
      navigate("/");
    } catch (error) {
      console.error("Login error", error);
    }
  };

  return (
    <View style={{ marginTop: "20vh" }}>
      <Heading level={1} className="header-text">
        Let’s sign you in.
      </Heading>
      <Heading level={2} className="header-2-text">
        Enter your details to access your data
      </Heading>
      <form onSubmit={handleSubmit(handleLogin)}>
        <View className="add-dispatch-container">
          <View className="form-block">
            <View>
              <Input
                type="email"
                placeholder="Email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className="error-text">This field is required</span>
              )}
            </View>
            <View>
              <PasswordField
                labelHidden
                label="Password"
                placeholder="Password"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span className="error-text">This field is required</span>
              )}
            </View>
            <Button
              className="submit-button-blue"
              variation="primary"
              loadingText="Submitting"
              isFullWidth
              type="submit"
            >
              Login
            </Button>
          </View>
        </View>
      </form>
    </View>
  );
};

export default Login;
