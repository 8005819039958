// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  width: 100%;
  padding: 16px 34px 17px 15px;
  border-bottom: 1px solid #353535;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #151515;
}
.header-container .search-input input {
  background: #202020;
  border-radius: 6px;
  border: none !important;
  padding: 7px 40px !important;
  padding-left: 30px;
  color: #9aa6ac;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.072px;
}
.header-container .search-input input::placeholder {
  color: #9aa6ac;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.072px;
}
.header-container .search-input input:focus {
  box-shadow: none;
}
.header-container .search-input-icon {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12.85px;
}
.header-container .search-input-icon img {
  min-width: 15.32px;
}
.header-container .profile-button {
  padding: 0;
}
.header-container .profile-button:focus, .header-container .profile-button:hover {
  background-color: transparent;
  box-shadow: none;
}
.header-container .profile-button img {
  min-width: 14px;
}

.amplify-select__icon {
  color: #ffc701 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,4BAAA;EACA,gCAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;AACF;AAEI;EACE,mBAAA;EACA,kBAAA;EACA,uBAAA;EACA,4BAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,gCAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,wBAAA;AAAN;AAEM;EACE,cAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,wBAAA;AAAR;AAEM;EACE,gBAAA;AAAR;AAGI;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AADN;AAGM;EACE,kBAAA;AADR;AAME;EACE,UAAA;AAJJ;AAMI;EAEE,6BAAA;EACA,gBAAA;AALN;AAQI;EACE,eAAA;AANN;;AAUA;EACE,yBAAA;AAPF","sourcesContent":[".header-container {\n  width: 100%;\n  padding: 16px 34px 17px 15px;\n  border-bottom: 1px solid #353535;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 10px;\n  background-color: #151515;\n\n  .search-input {\n    input {\n      background: #202020;\n      border-radius: 6px;\n      border: none !important;\n      padding: 7px 40px !important;\n      padding-left: 30px;\n      color: #9aa6ac;\n      font-size: 14px;\n      font-family: \"Inter\", sans-serif;\n      font-style: normal;\n      line-height: 24px;\n      font-weight: 400;\n      letter-spacing: -0.072px;\n\n      &::placeholder {\n        color: #9aa6ac;\n        font-family: \"Inter\", sans-serif;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 400;\n        letter-spacing: -0.072px;\n      }\n      &:focus {\n        box-shadow: none;\n      }\n    }\n    &-icon {\n      display: flex;\n      align-items: center;\n      height: 100%;\n      padding: 0 12.85px;\n\n      img {\n        min-width: 15.32px;\n      }\n    }\n  }\n\n  .profile-button {\n    padding: 0;\n\n    &:focus,\n    &:hover {\n      background-color: transparent;\n      box-shadow: none;\n    }\n\n    img {\n      min-width: 14px;\n    }\n  }\n}\n.amplify-select__icon {\n  color: #ffc701 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
