import { createColumnHelper } from "@tanstack/react-table";
import { Fragment, useEffect, useRef, useState } from "react";
import { Text, Flex, Heading, View } from "@aws-amplify/ui-react";
import { DateTime } from "luxon";

import { capitalizeFirstLetter, prepareOwnerTableData } from "../../../utils";
import { Table } from "../../../components/Cycles/Table";

import "./fleet-style.scss";

import { useLoads } from "../../../services/loadService";
import { useCycles } from "../../../services/cycleService";
import { useSalaries } from "../../../services/salaryService";
import { useSettings } from "../../../services/settingsService";
import { WeekSelector } from "../../../components/WeekSelector";

const DefaultColumn = ({
  getValue,
  row: { index },
  column: { id },
  table,
  textClass = "",
  number
}: {
  getValue: () => any;
  row: { index: number };
  column: { id: string };
  table: any;
  textClass?: string;
  number?: boolean;
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [editing, setEditing] = useState(
    initialValue || initialValue === 0 ? false : true
  );
  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (number && initialValue) setValue(initialValue.toFixed(2));
    else setValue(initialValue);
  }, [initialValue, number]);

  useEffect(() => {
    if (editing && value && value.length > 0) fieldRef?.current?.focus();
  }, [editing, value]);

  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
    setEditing(false);
  };

  return (
    <Fragment>
      {editing ? (
        <Flex direction="row">
          {number && <Text>$</Text>}
          <input
            ref={fieldRef}
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            style={{ width: 60 }}
          />
        </Flex>
      ) : (
        <Text
          className={`table-row-text ${textClass}`}
          onDoubleClick={() => setEditing(!editing)}
        >
          {number && "-$"}
          {value}
        </Text>
      )}
    </Fragment>
  );
};

const columnHelper = createColumnHelper();

const columns = [
  "#",
  "driverName",
  "gross",
  "diesel",
  "toll",
  "subTotal",
  "driverSalary",
  "total",
  "expenses",
  "fee",
  "netGross"
].map((item) =>
  columnHelper.accessor(item, {
    header: (info) => {
      const col = info.column.id;
      switch (col) {
        case "driverName":
          return "Driver";
        case "gross":
          return "Gross";
        case "subTotal":
          return "Sub Total";
        case "driverSalary":
          return "Driver Salary";
        case "netGross":
          return "Net Gross";
        default:
          return capitalizeFirstLetter(info.column.id);
      }
    },
    cell: (info) => {
      const value = info.getValue();
      const { gross, diesel, toll, salaryPercent, fee, expenses } =
        info.row.original;
      const driverSalary = ((gross - (diesel + toll)) / 100) * salaryPercent;
      const subTotal = gross - (diesel + toll);
      const total = subTotal - driverSalary;
      const feeValue = (gross / 100) * fee;
      const netGross = total - (expenses + feeValue);

      switch (item) {
        case "driverName":
          // Apply custom color to the Driver column
          return (
            <Text
              className="table-row-text DriverNameCol"
              style={{ color: "blue" }}
            >
              {value}
            </Text>
          );

        case "driverSalary":
          return (
            <Text className="table-row-text">
              <Text className="gray-text">{salaryPercent}% -</Text>
              <Text className="black-text">
                &nbsp;${driverSalary.toFixed(2)}
              </Text>
            </Text>
          );
        case "fee":
          return (
            <Text className="table-row-text feeCol">
              -${feeValue.toFixed(2)}
            </Text>
          );
        case "subTotal":
          return (
            <Text className="table-row-text subCol">
              ${subTotal.toFixed(2)}
            </Text>
          );
        case "total":
          return (
            <Text className="table-row-text total-text">
              ${total.toFixed(2)}
            </Text>
          );
        case "gross":
          return (
            <Text className="table-row-text grossAmount-text">
              ${value.toFixed(2)}
            </Text>
          );
        case "netGross":
          return (
            <Text className="table-row-text netGrossCol">
              ${netGross.toFixed(2)}
            </Text>
          );
        case "diesel":
        case "toll":
        case "expenses":
          return <DefaultColumn textClass={`${item}-text`} {...info} number />;
        case "#":
          return (
            <Text className="table-row-text index-text">
              {+info.row.id + 1}
            </Text>
          );
        default:
          return <Text className="table-row-text">{value}</Text>;
      }
    },
    footer: () => ""
  })
);

export const OwnerFleetScreen = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedMonthYear, setSelectedMonthYear] = useState({});
  const [selectedWeek, setSelectedWeek] = useState({});
  const { data: loadsData } = useLoads(startDate, endDate);
  const { data: cyclesData } = useCycles(startDate, endDate);
  const { data: salariesData } = useSalaries(startDate, endDate);
  const { data: settingsData } = useSettings(startDate, endDate);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (selectedWeek !== "") {
      setStartDate(DateTime.fromISO(selectedWeek.start).toUTC().toISO());
      setEndDate(DateTime.fromISO(selectedWeek.end).toUTC().toISO());
    }
  }, [selectedWeek]);

  useEffect(() => {
    if (
      loadsData?.length > 0 &&
      salariesData?.length > 0 &&
      settingsData?.length > 0
    ) {
      const fees = [settingsData[0].value, 10];
      setData(
        prepareOwnerTableData(loadsData, cyclesData || [], salariesData, fees)
      );
    }
  }, [loadsData, cyclesData, salariesData, settingsData]);

  return (
    <View className="fleet-container">
      <View className="fleet-header-container">
        <Heading className="header-text">Fleet</Heading>
        <WeekSelector
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          selectedMonthYear={selectedMonthYear}
          setSelectedMonthYear={setSelectedMonthYear}
        />
      </View>
      <View className="table-container">
        <Table data={data} setData={setData} columns={columns} />
      </View>
    </View>
  );
};
