import {
  View,
  Table as ATable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Text,
  Image
} from "@aws-amplify/ui-react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useEffect, useState } from "react";

import { defaultDateFormat } from "../../../constants";
import { capitalizeFirstLetter } from "../../../utils";
import "./sub-table-style.scss";
import ArrowUpIcon from "../../../assets/images/arrow-up-icon.svg";
import ArrowDownIcon from "../../../assets/images/arrow-down-icon.svg";

export const SubTable = ({ row: parentRow }) => {
  const [data, setData] = useState(parentRow?.original?.loads); // Why it's done this way? Not directly parentRow to useTable data?
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  useEffect(() => {
    setData(parentRow?.original?.loads);
  }, [parentRow]);

  const columns = [];

  [
    "#",
    "date",
    "load",
    "pickup",
    "dropoff",
    "price",
    "payment",
    "paymentStatus",
    "invoiceNumber",
    "broker"
  ].forEach((item) => {
    columns.push(
      columnHelper.accessor(item, {
        header: (info) => {
          if (item === "pickup") {
            return "Pick up";
          } else if (item === "dropoff") {
            return "Drop off";
          } else if (item === "invoiceNumber") {
            return "Load ID";
          } else if (item === "payment") {
            return "Method";
          } else if (item === "paymentStatus") {
            return "Payment Status";
          } else {
            return capitalizeFirstLetter(info.column.id);
          }
        },
        cell: (info) => {
          if (item === "date") {
            return format(new Date(info.getValue()), defaultDateFormat);
          } else if (item === "price") {
            return `$${info.getValue().toFixed(2)}`;
          } else if (item === "paymentStatus") {
            if (info.getValue().toLowerCase() === "unpaid") {
              return (
                <Text className="sub-table-row-text text-unpaid">
                  {info.getValue()}
                </Text>
              );
            } else if (info.getValue().toLowerCase() === "cash") {
              return (
                <Text className="sub-table-row-text text-cash">
                  {info.getValue()}
                </Text>
              );
            }
            return info.getValue();
          } else if (item === "#") {
            return (
              <Text className="table-row-text index-text">
                {+info.row.id + 1}
              </Text>
            );
          } else {
            return info.getValue();
          }
        },
        footer: ({ table }) => {
          return null;
        }
      })
    );
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true
  });

  return (
    <ATable size="small" highlightOnHover={true}>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableCell as="th" key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <View
                      {...{
                        className: `sub-table-header-item ${
                          header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : ""
                        }`,
                        onClick: header.column.getToggleSortingHandler()
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <Image
                            alt="arrow-up-icon"
                            src={ArrowUpIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        ),
                        desc: (
                          <Image
                            alt="arrow-down-icon"
                            src={ArrowDownIcon}
                            width="16px"
                            style={{
                              filter:
                                "brightness(0) saturate(100%) invert(0%) sepia(80%) saturate(7483%) hue-rotate(20deg) brightness(98%) contrast(100%)"
                            }}
                          />
                        )
                      }[header.column.getNextSortingOrder() as string] ?? null}
                    </View>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody className="sub-table-body">
        {table.getRowModel().rows.map((row, index) => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    key={cell.id}
                    className={`sub-table-row-text ${
                      table.getRowModel().rows.length - 1 === index
                        ? "none-border"
                        : ""
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </ATable>
  );
};
