// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-table-header-item {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #b7b7b7;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.sub-table-body th,
.sub-table-body td {
  border-bottom: 0px solid #474343;
}
.sub-table-body .none-border {
  border-bottom: 0 !important;
}
.sub-table-body .sub-table-row-text {
  color: #b7b7b7;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #282828;
}

.text-unpaid {
  text-transform: lowercase;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #421b1e;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.5px 5px;
}

.text-cash {
  text-transform: capitalize;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/Cycles/SubTable/sub-table-style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EAEA,cAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AAAF;;AAIE;;EAEE,gCAAA;AADJ;AAIE;EACE,2BAAA;AAFJ;AAKE;EACE,cAAA;EACA,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AAHJ;;AAOA;EACE,yBAAA;EACA,WAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,kBAAA;AAJF;;AAOA;EACE,0BAAA;EACA,WAAA;EACA,qCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAJF","sourcesContent":[".sub-table-header-item {\n  display: flex;\n  align-items: center;\n  gap: 6px;\n\n  color: #b7b7b7;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  white-space: nowrap;\n}\n\n.sub-table-body {\n  th,\n  td {\n    border-bottom: 0px solid #474343;\n  }\n\n  .none-border {\n    border-bottom: 0 !important;\n  }\n\n  .sub-table-row-text {\n    color: #b7b7b7;\n    font-family: \"Inter\", sans-serif;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    background-color: #282828;\n  }\n}\n\n.text-unpaid {\n  text-transform: lowercase;\n  color: #000;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  background: #421b1e;\n  width: fit-content;\n  padding: 1.5px 5px;\n}\n\n.text-cash {\n  text-transform: capitalize;\n  color: #000;\n  font-family: \"Montserrat\", sans-serif;\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
