import { TextField, View, Button, Image } from "@aws-amplify/ui-react";

import { Roles } from "../../constants";
import "./style.scss";
import SearchIcon from "../../assets/images/search-icon.svg";
import ProfileIcon from "../../assets/images/profile-icon.svg";
import Logo from "../../assets/images/taqtic-logo.svg";
import { SubButton } from "../SubButton";

import { useNavigate } from "react-router-dom";

import { useUser } from "../../context/userContext";

export const Header = () => {
  const navigate = useNavigate();

  const {
    user: { roles }
  } = useUser();

  return (
    <>
      {roles.length === 0 ? (
        <View className="header-front-container">
          <View className="logo-block">
            <Image alt="logo" src={Logo} width="110px" height="24.25px" />
          </View>
        </View>
      ) : (
        <View className="header-container">
          <TextField
            className="search-input"
            placeholder="Search here"
            label="Search"
            innerStartComponent={
              <View className="search-input-icon">
                <Image alt="search-icon" src={SearchIcon} width="18.32px" />
              </View>
            }
            inputStyles={{
              border: "1px solid #DDE2E4",
              borderRadius: 6,
              padding: "4px 40px",
              color: "#9AA6AC",
              fontFamily: "Inter, sans-serif",
              fontSize: 12,
              fontStyle: "normal",
              fontWeight: 400,
              letterSpacing: -0.072
            }}
            width="265px"
            labelHidden
          />
          <View style={{ flexDirection: "row", display: "flex", gap: "20px" }}>
            <View style={{ flexDirection: "row", display: "flex", gap: "0px" }}>
              {(roles?.includes(Roles.HeadDispatch) ||
                roles?.includes(Roles.SuperAdmin)) && (
                <SubButton
                  className="topbarcontrol"
                  onClick={() => navigate("/addDispatch")}
                  title="Add Dispatch"
                />
              )}
              {/* incorrect roles btw {(roles?.includes(Roles.Dispatch) ||
                roles?.includes(Roles.HeadDispatch) ||
                roles?.includes(Roles.SuperAdmin)) && (
                <SubButton
                  className="topbarcontrol"
                  onClick={() => navigate("/addDriverHeadDispatch")}
                  title="Add Driver"
                />
              )} */}
              {(roles?.includes(Roles.HeadDispatch) ||
                roles?.includes(Roles.SuperAdmin)) && (
                <SubButton
                  className="topbarcontrol"
                  onClick={() => navigate("/addDriverDispatch")}
                  title="Link Driver"
                />
              )}
            </View>

            <Button
              className="profile-button"
              variation="link"
              onClick={() => {}}
            >
              <Image alt="profile-icon" src={ProfileIcon} width="14px" />
            </Button>
          </View>
        </View>
      )}
    </>
  );
};
