import { View, Image, Text, Button } from "@aws-amplify/ui-react";
import { NavLink } from "react-router-dom";

import LoadsIcon from "../../assets/images/loads.svg";
import FleetIcon from "../../assets/images/fleet.svg";
import AccountingIcon from "../../assets/images/accounting.svg";
import PowerIcon from "../../assets/images/power-icon.svg";
import ArrowIcon from "../../assets/images/arrow-icon.svg";
import LogoMini from "../../assets/images/taqtic-logo-mini.svg";
import Logo from "../../assets/images/taqtic-logo.svg";
import { SidebarProps, Roles } from "../../constants";
import { useUser } from "../../context/userContext";
import "./style.scss";
import useLogout from "../../hooks/useLogout";

export const Sidebar = ({
  visibleSidebar,
  setVisibleSidebar
}: SidebarProps) => {
  const {
    user: { roles }
  } = useUser();
  const { handleLogout } = useLogout();
  return (
    <View className={`sidebar ${!visibleSidebar ? "sidebar-small" : ""}`}>
      <View className="sidebar-container">
        <View className="logo-block">
          {visibleSidebar ? (
            <Image alt="logo" src={Logo} width="auto" height="24.25px" />
          ) : (
            <Image alt="logo" src={LogoMini} width="25.6px" />
          )}
        </View>
        <View className="menu-container">
          <View className="menu-list">
            {(roles?.includes(Roles.Owner) ||
              roles?.includes(Roles.SuperAdmin)) && (
              <NavLink
                to="owner"
                className={({ isActive }) =>
                  isActive ? "menu-item menu-item-active" : "menu-item"
                }
              >
                <Image alt="fleet-icon" src={FleetIcon} width="17px" />
                <Text>Fleet</Text>
              </NavLink>
            )}
            {(roles?.includes(Roles.Dispatch) ||
              roles?.includes(Roles.HeadDispatch) ||
              roles?.includes(Roles.SuperAdmin)) && (
              <NavLink
                to="dispatch"
                className={({ isActive }) =>
                  isActive ? "menu-item menu-item-active" : "menu-item"
                }
              >
                <Image src={LoadsIcon} width="17px" />
                <Text>Loads</Text>
              </NavLink>
            )}
            {(roles?.includes(Roles.Accountant) ||
              roles?.includes(Roles.SuperAdmin)) && (
              <NavLink
                to="accountant"
                className={({ isActive }) =>
                  isActive ? "menu-item menu-item-active" : "menu-item"
                }
              >
                <Image src={AccountingIcon} width="17px" />
                <Text>Accounting</Text>
              </NavLink>
            )}
          </View>
          <View className="menu-footer">
            <Button
              className="menu-footer-button"
              variation="link"
              onClick={() => handleLogout()}
            >
              <Image alt="power-icon" src={PowerIcon} width="15px" />
            </Button>
          </View>
        </View>
      </View>
      <Button
        className="hide-button"
        variation="link"
        size="small"
        onClick={() => setVisibleSidebar(!visibleSidebar)}
      >
        <Image alt="arrow-icon" src={ArrowIcon} width="8px" />
      </Button>
    </View>
  );
};
