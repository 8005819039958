// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-dispatch-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.add-dispatch-container .form-block {
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 480px;
}
.add-dispatch-container .form-block .submit-button {
  background-color: #ffbe2f;
  color: #141414;
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/screens/headDispatch/AddDispatch/dispatch-style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;AACJ;AACI;EACE,yBAAA;EACA,cAAA;EACA,uBAAA;EAAA,kBAAA;AACN","sourcesContent":[".add-dispatch-container {\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n\n  .form-block {\n    gap: 0.75rem;\n    display: flex;\n    flex-direction: column;\n    max-width: 480px;\n    width: 480px;\n\n    .submit-button {\n      background-color: #ffbe2f;\n      color: #141414;\n      width: fit-content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
