import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, View, Image } from "@aws-amplify/ui-react";

import apiClient from "../../../api/axios";
import "../../headDispatch/AddDispatch/dispatch-style.scss";

const Setup2FA: React.FC = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const setup2FA = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await apiClient.post(
          "/2fa/setup",
          {},
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        setQrCodeUrl(response.data.qrCodeUrl);
      } catch (error) {
        console.error("2FA setup error", error);
      }
    };
    setup2FA();
  }, []);

  const handleVerify2FA = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = (e.target as any).elements.token.value;
    try {
      const authToken = localStorage.getItem("token");
      await apiClient.post(
        "/2fa/verify",
        { token },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      navigate("/");
    } catch (error) {
      console.error("2FA verification error", error);
    }
  };

  return (
    <form onSubmit={handleVerify2FA}>
      <View className="add-dispatch-container">
        <View className="form-block">
          <h2>Setup 2FA</h2>
          <View>
            <Image src={qrCodeUrl} alt="QR Code" />
          </View>
          <View>
            <Input type="text" placeholder="Token" name="token" required />
          </View>
          <Button
            className="submit-button"
            variation="primary"
            loadingText="Verifying"
            type="submit"
          >
            Verify
          </Button>
        </View>
      </View>
    </form>
  );
};

export default Setup2FA;
