import { useForm } from "react-hook-form";
import { View, Button, SelectField } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import apiClient from "../../../api/axios";
import "./driver-dispatch-style.scss";

export const AddDriverDispatchScreen = () => {
  const [drivers, setDrivers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [dispatches, setDispatches] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    const post = { ...data };
    apiClient
      .post("/dispatch/processLinkUser", post)
      .then((res) => {
        if (res.data.success) {
          alert("success");
        } else {
          throw new Error("Fail");
        }
      })
      .catch((e) => {
        alert("error!");
        console.log(e);
      });
  };

  useEffect(() => {
    apiClient
      .get("/dispatch/drivers")
      .then((res) => {
        if (res.data.length > 0) {
          setDrivers(res.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching drivers", e);
      });

    apiClient
      .get("/dispatch/owners")
      .then((res) => {
        if (res.data.length > 0) {
          setOwners(res.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching owners", e);
      });

    apiClient
      .get("/dispatch/dispatches")
      .then((res) => {
        if (res.data.length > 0) {
          setDispatches(res.data);
        }
      })
      .catch((e) => {
        console.error("Error fetching dispatchers", e);
      });
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <View className="add-driver-dispatch-container">
        <View className="form-block">
          <View>
            <SelectField
              label="Driver"
              descriptiveText="Select driver"
              labelHidden={false}
              {...register("driver", { required: true })}
            >
              {drivers.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.email}
                </option>
              ))}
            </SelectField>
            {errors.driver && (
              <span className="error-text">This field is required</span>
            )}
          </View>

          <View>
            <SelectField
              label="Owner"
              descriptiveText="Select owner"
              labelHidden={false}
              {...register("owner", { required: true })}
            >
              {owners.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.email}
                </option>
              ))}
            </SelectField>
            {errors.owner && (
              <span className="error-text">This field is required</span>
            )}
          </View>

          <View>
            <SelectField
              label="Dispatch"
              descriptiveText="Select dispatch"
              labelHidden={false}
              {...register("dispatch", { required: true })}
            >
              {dispatches.map((option) => (
                <option key={option._id} value={option._id}>
                  {option.email}
                </option>
              ))}
            </SelectField>
            {errors.dispatch && (
              <span className="error-text">This field is required</span>
            )}
          </View>

          <Button
            className="submit-button"
            variation="primary"
            loadingText="Submitting"
            type="submit"
          >
            Submit
          </Button>
        </View>
      </View>
    </form>
  );
};
